// -----------------------------------------------------------------------------
// This file contains all styles related to the CONTENT layout.
// -----------------------------------------------------------------------------


// ==== Global Layout Structure ==== //

app, admin {
  display: block;
  height: 100%;
  min-width: $global-width;
  overflow: hidden;

  // ==== View Container Elements (base styles in app.component.scss)

  .view-header {
    .view-header-title { margin: 0;}
    .view-header-actions { button {margin: 0;}}

    .actionsViewHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: end;
      gap: rem-calc(8px);

      button {
        flex: 1 1 auto;
      }
    }
  }
}

// ==== Bloc Container ==== //

.block-container {
  position: relative;
  border: rem-calc(1px) solid $grey-ultra-light;
  border-radius: $global-radius;
  @extend %box-shadow;
  background-color: white;

  .block-header {
    padding: $block-gutter-header;
  }
  .block-content {
    padding: $block-gutter-content;
  }
}

.block-container-explain {
  position: relative;
  margin: rem-calc(10px);
  padding: rem-calc(8px);
  border: rem-calc(1px) solid $grey-ultra-light;
  border-radius: $global-radius;
  background-color: lightblue;
  width: 100%;
  @extend %box-shadow;

  .block-header {
    padding: $block-gutter-header;
  }
  .block-content {
    padding: $block-gutter-content;
  }
}

.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.block-bordered {
    border: rem-calc(1px) solid $grey-light;
    border-radius: $global-radius * 2.5;

  &._bgGrey {
    background-color: $special-grey;
  }

  &._is-active {
    border: rem-calc(1px) solid $app-primary;
    background: white;
  }

  &._hover {
    cursor: pointer;

    &:hover, &:focus {
      @extend %globalTransition;
      box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    }

    &:active {
      @extend %globalTransition;
      box-shadow: none;
    }
  }

  &._fullHeight {
    min-height: 100%;
  }

  .block-bordered-header {
    padding: rem-calc(12px 16px);
    border-radius: rem-calc(8px 8px 0 0);
    border-bottom: rem-calc(1px) solid $lineGrey;

    &._bgPrimary {
      background-color: $primary;
      color: $app-white;
    }
  }

  .block-bordered-content {
    padding: $block-gutter-content;
  }

  .block-bordered-footer {
    padding: $block-gutter-content;
    background-color: $special-grey;
    border-top: rem-calc(1px) solid $lineGrey;
    border-radius: 0 0 12px 12px;
  }
}

.blocHead {
  &-btn {
    a-button-round {
      margin-right: rem-calc(8px);
    }
  }

  &-title {
    margin-right: rem-calc(8px);
    margin-bottom: 0;
  }

  &-spacer {
    width: rem-calc(42px) !important;
  }
}

// ==== Bloc Advices ==== //

.block-advice {
  position: relative;
  padding: rem-calc(8px 16px 44px 16px);
  border-radius: rem-calc(8px);
  background: url($common-jpg-path + 'bg-advice.jpg');
  background-size: cover;
  @extend %box-shadow;

  .bandeau-label {
    position: absolute;
    bottom: rem-calc(16px);
    right: rem-calc(32px);
    transform: rotate(-35deg);
    color: white;
    font-size: rem-calc(11px);
    z-index: 1;
  }

  &:after {
    position: absolute;
    bottom: 0;
    right: 0;
    height: rem-calc(92px);
    width: rem-calc(130px);
    content: '';
    background: url($common-svg-path + 'bandeau-aprouved.svg');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .block-advice-header {
    margin: rem-calc(16px 0 28px 0);

    .block-advice-header-title {
      img {
        display: inline-block;
        vertical-align: text-bottom;
        width: rem-calc(64px);
        margin-right: rem-calc(8px);
      }

      .title {
        display: inline-block;
        vertical-align: bottom;
        margin: 0;
      }
    }
  }

  .block-advice-content-list {
    margin: 0;

    .block-advice-content-item {
      margin: rem-calc(8px 0);

      .list-item-nb {
        position: relative;
        top: rem-calc(4px);
        display: block;
        height: rem-calc(34px);
        min-width: rem-calc(34px);
        border-radius: 100%;
        font-size: rem-calc(22px);
        color: $primary;
        background-color: white;
        text-align: center;
        line-height: rem-calc(34px);
      }

      .list-item-text {
        color: white;
        font-weight: 500;
        white-space: normal;
      }
    }
  }
}

// ==== Container Img ==== //

// === Type Round

.img-container-round {
  display: inline-block;
  overflow: hidden;
  height: rem-calc(56px);
  width: rem-calc(56px);
  margin: rem-calc(0 8px);
  border-radius: 100%;

  img {
    height: 100%;
    object-fit: cover !important;
  }

  //== Size

  &.small { height: rem-calc(34px); width: rem-calc(34px);}
  &.large { height: rem-calc(64px); width: rem-calc(64px);}
  &.xlarge { height: rem-calc(152px); width: rem-calc(152px);}
  &.xxlarge { height: rem-calc(200px); width: rem-calc(200px);}
}

// ==== Sticker Advice ==== //

.sticker-advice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: rem-calc(164px);
  width: rem-calc(164px);
  background: url($common-jpg-path + 'bg-advice.jpg');
  background-size: cover;
  border-radius: 100%;
  text-align: center;

  .icon-advice {
    width: rem-calc(54px);
  }

  .logo-entity {
    width: rem-calc(100px);
  }
}

.btnsGroup {
  display: flex;
  flex-direction: row;
  column-gap: rem-calc(8px);
  padding-inline-end: rem-calc(16px);
}




