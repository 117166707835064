// -----------------------------------------------------------------------------
// This file contains all styles related to the COLLAPSE component.
// -----------------------------------------------------------------------------

// ==== Custom Style Callout (base in vendors/foundation-variables.scss) ==== //


.module-choice {
  position: relative;
  margin-bottom: rem-calc(8px);
  padding: rem-calc(8px 16px);
  border-radius: rem-calc(8px);
  background: mix($primary, $white, 4%);

  &:hover:after {
    background: rgba($primary-dark, 0.6);
    transition: 300ms ease-in-out;
  }

}

