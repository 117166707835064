@charset "utf-8";

// ~ IMPORT ALL SASS
// ====================================================================== >
// For more informations on sass organization, to know where to put which files, etc.
// see : http://sass-guidelin.es/fr/#le-pattern-7-1
// If you have doubts, put your declarations at the end of this file. I will move these later.
@import 'styles/utils/variables';

// ~ VENDORS
// ====================================================================== >
$contrast-warnings : false;
@import 'styles/vendors/foundation-settings';
@import '../../node_modules/foundation-sites/scss/foundation';
@include foundation-everything($flex: true);

@import 'common/styles/base-main';


// === Fix scroll login
html, body {
  height: inherit !important;
}
