// -----------------------------------------------------------------------------
// This file contains all styles related to the DROPDOWN components.
// -----------------------------------------------------------------------------

// ==== Layout Dropdown ==== //

.dropdown {
  border: rem-calc(1px) solid $grey-ultra-light;
  border-radius: $global-radius;
  @extend %box-shadow;
  background-color: white;
  max-width: rem-calc(350px);
  z-index: 999;

  .block-header { padding: $block-gutter-header;}
  .block-content { padding: $block-gutter-content;}

  .item-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  i + .item-label {
      width: calc(100% - 30px);
  }

  .flag-notif {
    i {
      position: absolute !important;
      top: 50% !important;
    }
  }
}

.icon-dropDown {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &.right {
    right: 18px;
    top: 40%;
    font-size: 22px;

    &:hover {
      opacity: 0.3;
    }
  }

  &.left {
    left: 75px;
    top: 45%;
  }
}
