// -----------------------------------------------------------------------------
// This file contains all styles related to the PLACEHOLDER utils.
// -----------------------------------------------------------------------------

// ==== Global transition ==== //

%globalTransition {
  transition: 190ms;
}

// ==== Absolute Center in Bloc ==== //

%posAbsoluteCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// ==== ABox shadow placeholder ==== //

%box-shadow, %blocShadow {
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.14);
}

// ==== Callout Icon ==== //

%calloutIcon {
  font-family: 'beclm-cu';
  position: absolute;
  top: 50%;
  left: rem-calc(8px);
  transform: translate(0, -50%);
  font-size: rem-calc(34px) !important;
  opacity: 0.15;
}

/**
 * Ripple Effect
 * **************************************************************************************** >
 */
%rippleEffect {
  position: relative;
  overflow: hidden;

  //** ===== Modifiers ===== **//
  &._ripple-dark { &:after {  background-image: radial-gradient(circle, $app-grey_dark 11%, transparent 2%);} }
  &._ripple-primary { &:after {  background-image: radial-gradient(circle, $app-primary 11%, transparent 2%);} }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 5%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0,0);
    opacity: .2;
    transition: 0s;
  }
}

// ==== Transition for animation placeholder
%transition {
  transition: all 0.25s;
}
