// -----------------------------------------------------------------------------
// This file contains all styles related to the LIST NAV components.
// -----------------------------------------------------------------------------

// ==== List Nav ==== //

.list-nav {
  margin: 0;

  .list-nav-item {
    position: relative;
    display: block;
    margin-top: rem-calc(-1px);
    border-top: rem-calc(1px) solid $sep-line-color;
    border-bottom: rem-calc(1px) solid $sep-line-color;

    &:last-child { border-bottom: 0;}


    .flag {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
    }

    .list-nav-item-content {
      display: block;
      height: 100%;
      width: 100%;
      padding: rem-calc(4px 8px);
      color: $grey-dark;

      &.logout { color: $red;}

      &:hover, &:focus, &:active {
        @extend %globalTransition;
        color: $grey-dark;
      }

      i {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        top: rem-calc(2px);
        margin-right: rem-calc(8px);
        font-size: rem-calc(22px);
      }

      .item-label {
        display: inline-block;
        vertical-align: middle;
        font-size: rem-calc(15px);
        font-weight: 500;
      }
    }
  }
}
