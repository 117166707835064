// ---------------------------------------------------------------------- >
// ~ SEARCH HELP
// ---------------------------------------------------------------------- >
// Hierarchy :
// ~  : High level section. Title is in summary, separate by "arrow" at the begin and the end (except search help and summary).
// ==== : Upper level in a section
// == : Sub level in a section
// = : Lower level in a section

// ---------------------------------------------------------------------- >
// ~ SUMMARY
// ---------------------------------------------------------------------- >
// 1 - MISC
// 2 - GLOBAL LAYOUT
// 3 - SIDEBAR
// 4 - STATUS

// ---------------------------------------------------------------------- >
// ~ MISC
// ---------------------------------------------------------------------- >

// ==== Define Fonts
$body-font:  'Work Sans', Arial, sans-serif;

// ==== Define Colors
$primary :                                      var(--primary) !default;
$primary-light :                                var(--primary-light) !default;
$primary-dark :                                 var(--primary-dark) !default;
$primary-sub :                                  var(--primary-sub) !default;

// == (New Names Primary Variables)
$app-primary :                                  var(--primary) !default;
$app-primary-light :                            var(--primary-light) !default;
$app-primary-medium :                           var(--primary-medium) !default;
$app-primary-dark :                             var(--primary-dark) !default;
$app-primary-sub :                              var(--primary-sub) !default;

$primary-gradient :                             linear-gradient(135deg, $app-primary_dark  0%, $app-primary 100%) !default;
$primary-gradient-light :                       linear-gradient(135deg, $app-primary_light  0%, $app-primary_dark 100%) !default;
$primary-gradient-vertical:                     linear-gradient(0deg, $app-primary_dark 0%, $app-primary 100%) !default;

$yellow-gradient :                              linear-gradient(135deg, #FDBF36 0%, #D59916 100%) !default;
$blue-gradient :                                linear-gradient(135deg, #6481FD 0%, #3F55B4 100%) !default;
$lightGreen-gradient :                          linear-gradient(135deg, #7bd642 0%, #69bc1f 100%) !default;

$green :                                        #87D122 !default;
$yellow :                                       #F2B746 !default;
$red :                                          #FF3434 !default;
$blue :                                         #006CFF !default;

$app-disabled-bgColor:                          #F5F5F5 !default;
$app-disabled-fontColor:                        #C7C7C7 !default;

$app-success-color :                            $green !default;
$app-in-progress-color :                        $blue !default;
$app-warning-color :                            $yellow !default;
$app-reinforced-color :                         $yellow !default;
$app-error-color :                              $red !default;

$special-grey :                                 #f1f1f1 !default;
$app-special :                                  #D5EAE3 !default;

$grey-ultra-light :                             #f1f1f1 !default;
$grey-light :                                   #d9d9d9 !default;
$grey-medium :                                  #bcbcbc !default;
$grey-dark :                                    #8B8E90 !default;
$black :                                        #4C5053 !default;

// == (New Names Variables)
$app-success :                                  $green !default;
$app-progress :                                 $blue !default;
$app-warning :                                  $yellow !default;
$app-error :                                    $red !default;

$app-white :                                    #FFFFFF !default;
$app-grey_light :                               #d9d9d9 !default;
$app-grey_medium :                              #bcbcbc !default;
$app-grey_dark :                                #8B8E90 !default;
$app-black :                                    #4C5053 !default;

$lineGrey :                                     #E4E7EF !default;
$hoverGrey :                                    #F5F7FD !default;
$hoverGreyLight :                               rgba($hoverGrey, 0.6) !default;

$productPrimary :                               #4E3D75 !default;
$personPrimary :                                #58ABAD !default;
$labPrimary :                                   #E61F25 !default;
$ComplianceBinderPrimary :                      #5263E5 !default;
$blackListPrimary :                             #e27ca8 !default;
$adverseMediaPrimary :                          #EA6A2C !default;
$pepPrimary :                                   #f0ae42 !default;
$dashboardPrimary :                             #1E3C58 !default;
$elearningPrimary :                             #d54f46 !default;
$user-secondary :                               #32ADE7 !default;

$NOT_COVERED :                                  mix(#FF3434, white, 10%) !default;
$NOT_COVERED_UNUSED :                           mix($app-grey_medium, white, 30%) !default;
$MANDATORY :                                    mix(#F2B746, white, 10%) !default;
$PACKAGE_DISCOUNTED :                           mix(#9334FF, white, 10%) !default;
$NO_WARNING :                                   mix($special-grey, white, 40%) !default;

$appModuleColor : (
  'company' : #32ADE7,
  'complianceBinder' : #5263E5,
  'lab' : #E61F25,
  'blacklist': #f0ae42,
  'adverseMedia': #EA6A2C,
  'pep' : #e27ca8,
  'beclm' : #1E3C58,
  'product' : #4E3D75,
  'elearning' : #d54f46,
) !default;

// = General Variables

// = PATHS
$common-font-main-path:                         '../../../common/assets/fonts/' !default;
$common-svg-path :                              '../../../common/assets/images/svg/' !default;
$common-jpg-path :                              '../../../common/assets/images/jpg/' !default;
$common-png-path :                              '../../../common/assets/images/png/' !default;
$common-gif-path :                              '../../../common/assets/images/gif/' !default;

// = IMAGES
$app-logo :                                     'common/assets/images/svg/modules-icons/beclm_lab_logo_white.svg' !default;
$entity-logo :                                  'common/assets/images/svg/modules-icons/beclm_logo_white.svg' !default;

// = METRICS
$height-header :                                 rem-calc(72px) !default;
$height-envBanner :                              rem-calc(48px) !default;
$width-sidebar-retract :                         rem-calc(100px) !default;
$width-sidebar :                                 rem-calc(296px) !default;
$app-view-gutter :                               rem-calc(16px 24px) !default;
$block-gutter-header :                           rem-calc(16px 16px 0 16px) !default;
$block-gutter-content :                          rem-calc(16px) !default;
$border-radius-flag :                             rem-calc(6px) !default;
$large-radius :                                  rem-calc(8px);
$xlarge-radius :                                 rem-calc(10px);

// = SETTINGS
$contrast-warnings: false;

// < ----------------------------------------------------------------------
// ~ MISC END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ GLOBAL LAYOUT
// ---------------------------------------------------------------------- >

// == Colors
$background-side-menu :                           $special-grey !default;
$sep-line-color :                                 $grey-light !default;
$section-title :                                  $app-primary !default;

// ---------------------------------------------------------------------- >
// ~ SIDEBAR
// -------------------------- -------------------------------------------- >

// == Colors
$border-side-menu :                                $grey-medium !default;

// == Metrics
$gutter-side-menu :                                 rem-calc(24px) !default;

// ---------------------------------------------------------------------- >
// ~ STATUS
// -------------------------- -------------------------------------------- >

// == Colors

$status-MISSING :                                        $grey-medium !default;
$status-OUTDATED :                                       $yellow !default;
$status-TOCHECK :                                        $app-primary_light !default;
$status-UPTODATE :                                       $green !default;
$status-NOT_SUITABLE:                                    $grey-medium !default;

$status-PENDING:                                         $grey-medium !default;
$status-IN_PROGRESS:                                     $blue !default;
$status-TERMINATED:                                      $green !default;

$status-DRAFTED:                                         $yellow !default;
$status-PUBLISHED:                                       $green !default;
$status-ARCHIVED:                                        $blue !default;
