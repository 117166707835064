.filters {
    border-radius: $global-radius;
}

.filters-heading {
    font-size: rem-calc(22px);
    color: $special-grey;
    background-image: $primary-gradient;
    border-radius: $global-radius $global-radius 0 0;
    text-transform: uppercase;
}

.filters-content {
  border: rem-calc(1px) solid $primary;
}
