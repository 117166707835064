// -----------------------------------------------------------------------------
// This file contains all styles related to the APP LOADER components.
// ----------------------------------------------------------------------------

// ==== App Loader ==== //

.app-loader {
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  &._root {
    top: 50%;
  }

  .spinner {
    position: absolute;
    @extend %posAbsoluteCenter;
    height: rem-calc(64px);
    width: rem-calc(64px);
    background: url($common-svg-path + "tail_spin.svg");
    transform: translate(-50%, -50%) rotate(135deg);
    background-size: 100%;
    display: block;
  }

  .title-spinner {
    position: absolute;
    left: 50%;
    @extend %posAbsoluteCenter;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    letter-spacing: rem-calc(2px);
    background: white;
  }
}
