// ---------------------------------------------------------------------- >
// ~ SEARCH HELP
// ---------------------------------------------------------------------- >
// Hierarchy :
// ~  : High level section. Title is in summary, separate by "arrow" at the begin and the end (except search help and summary).
// ==== : Upper level in a section
// == : Sub level in a section
// = : Lower level in a section

// ---------------------------------------------------------------------- >
// ~ SUMMARY
// ---------------------------------------------------------------------- >
// 1 - MISC
// 2 - GLOBAL LAYOUT
// 3 - SIDEBAR
// 4 - STATUS

// ---------------------------------------------------------------------- >
// ~ MISC
// ---------------------------------------------------------------------- >

// ==== Define Fonts
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$body-font:  'Work Sans', Arial, sans-serif;
$secondary-font: 'PT Sans', sans-serif;

// ==== Define Colors
:root {
  --primary:                                    #4358DB;
  --primary-light:                              #465be8;
  --primary-medium:                             #3b4cc8;
  --primary-dark:                               #2d399d;
  --primary-sub:                                #2c3899;
}

// ==== Define Colors
$primary :                                      #4358DB;
$primary-light :                                lighten($primary, 10%);
$primary-dark :                                 darken($primary, 10%);
$primary-sub :                                  #2c3899;
$primary-gradient :                             linear-gradient(135deg, $primary-sub 0%, $primary 100%);
$primary-gradient-vertical:                     linear-gradient(0deg, $primary-sub 0%, $primary 100%);

// == (New Names Primary Variables)
$app-primary :                                  #4358DB;
$app-primary-light :                            #465be8;
$app-primary-medium :                           #3b4cc8;
$app-primary-dark :                             #2d399d;
$app-primary-sub :                              #2c3899;

$productPrimary :                               #4E3D75;
$personPrimary :                                #58ABAD;
$labPrimary :                                   #E61F25;
$ComplianceBinderPrimary :                      #5263E5;
$blackListPrimary :                             #f0ae42;
$pepPrimary :                                   #e27ca8;
$user-secondary :                               #32ADE7;
$elearningPrimary :                             #d54f46;


$green :                                        #87D122;
$yellow :                                       #F2B746;
$red :                                          #FF3434;
$blue :                                         #006CFF;

$app-success-color :                            $green;
$app-in-progress-color :                        $blue;
$app-warning-color :                            $yellow;
$app-reinforced-color :                         $yellow;
$app-error-color :                              $red;

$special-grey :                                 mix(white, $primary-light, 96%);
$grey-ultra-light :                             #f1f1f1;
$grey-light :                                   #d9d9d9;
$grey-medium :                                  #bcbcbc;
$grey-dark :                                    #8B8E90;
$black :                                        #213D57;


// = General Variables

// = PATHS
$font-main-path:                                '../../../assets/fonts/';
$svg-path :                                     '../../../assets/images/svg/';
$jpg-path :                                     '../../../assets/images/jpg/';
$png-path :                                     '../../../assets/images/png/';
$gif-path :                                     '../../../assets/images/gif/';

// = IMAGES
$app-logo :                                     '../../../../../../common/assets/images/svg/modules-icons/beclm_logo.svg';
$entity-logo-login :                            '../../../../../../common/assets/images/svg/modules-icons/beclm_logo.svg';

// = METRICS
$height-header :                                 rem-calc(72px);
$width-sidebar-retract :                         rem-calc(100px);
$width-sidebar :                                 rem-calc(296px);
$app-view-gutter :                               rem-calc(16px 24px);
$block-gutter-header :                           rem-calc(16px 16px 0 16px);
$block-gutter-content :                          rem-calc(16px);
$border-radius-flag :                            rem-calc(6px);

// = SETTINGS
$contrast-warnings: false;

// < ----------------------------------------------------------------------
// ~ MISC END
// < ----------------------------------------------------------------------

// ---------------------------------------------------------------------- >
// ~ GLOBAL LAYOUT
// ---------------------------------------------------------------------- >

// == Colors
$background-side-menu :                           $special-grey;
$sep-line-color :                                 $grey-light;
$section-title :                                  $primary;

// ---------------------------------------------------------------------- >
// ~ SIDEBAR
// -------------------------- -------------------------------------------- >

// == Colors
$border-side-menu :                                $grey-medium;

// == Metrics
$gutter-side-menu :                                 rem-calc(24px);

// ---------------------------------------------------------------------- >
// ~ STATUS
// -------------------------- -------------------------------------------- >

// == Colors

$status-MISSING :                                        $grey-medium;
$status-OUTDATED :                                       $yellow;
$status-TOCHECK :                                        $primary-light;
$status-UPTODATE :                                       $green;
