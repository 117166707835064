@charset "UTF-8";
@import "../utils/variables";

@font-face {
  font-family: "beclm-cu";
  src:url($common-font-main-path + "icons/beclm-cu.eot");
  src:url($common-font-main-path + "icons/beclm-cu.eot?#iefix") format("embedded-opentype"),
  url($common-font-main-path + "icons/beclm-cu.woff") format("woff"),
  url($common-font-main-path + "icons/beclm-cu.ttf") format("truetype"),
  url($common-font-main-path + "icons/beclm-cu.svg#beclm-cu") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  display: inline-block;
  vertical-align: middle;
  font-family: "beclm-cu" !important;
}

[data-icon]:before {
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "beclm-cu" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-section-masse-qualification:before {
  content: "\e8f8";
}
.icon-mapping:before {
  content: "\e81b";
}
.icon-flux:before {
  content: "\e81a";
}
.icon-injector:before {
  content: "\e81d";
}
.icon-section-audit:before {
  content: "\e80d";
}
.icon-section-batch:before {
  content: "\e80a";
}
.icon-section-import:before {
  content: "\e80b";
}
.icon-section-search:before {
  content: "\e80c";
}
.icon-section-stats:before {
  content: "\e80e";
}
.icon-angle:before {
  content: "\41";
}
.icon-angry-monster:before {
  content: "\42";
}
.icon-animal-bat:before {
  content: "\43";
}
.icon-animal-bear:before {
  content: "\44";
}
.icon-animal-bear-tracks:before {
  content: "\45";
}
.icon-animal-bird:before {
  content: "\46";
}
.icon-animal-bird-alt:before {
  content: "\47";
}
.icon-animal-bone:before {
  content: "\48";
}
.icon-animal-bull:before {
  content: "\49";
}
.icon-animal-camel:before {
  content: "\4a";
}
.icon-animal-camel-alt:before {
  content: "\4b";
}
.icon-animal-camel-head:before {
  content: "\4c";
}
.icon-animal-cat:before {
  content: "\4d";
}
.icon-animal-cat-alt-1:before {
  content: "\4e";
}
.icon-animal-cat-alt-2:before {
  content: "\4f";
}
.icon-animal-cat-alt-3:before {
  content: "\50";
}
.icon-animal-cat-alt-4:before {
  content: "\51";
}
.icon-animal-cat-with-dog:before {
  content: "\52";
}
.icon-animal-cow:before {
  content: "\53";
}
.icon-animal-cow-head:before {
  content: "\54";
}
.icon-animal-crab:before {
  content: "\55";
}
.icon-animal-crocodile:before {
  content: "\56";
}
.icon-animal-deer-head:before {
  content: "\57";
}
.icon-animal-dog:before {
  content: "\58";
}
.icon-animal-dog-alt:before {
  content: "\59";
}
.icon-animal-dog-barking:before {
  content: "\5a";
}
.icon-animal-dolphin:before {
  content: "\30";
}
.icon-animal-duck-tracks:before {
  content: "\31";
}
.icon-animal-eagle-head:before {
  content: "\32";
}
.icon-animal-eaten-fish:before {
  content: "\33";
}
.icon-animal-elephant:before {
  content: "\34";
}
.icon-animal-elephant-alt:before {
  content: "\35";
}
.icon-animal-elephant-head:before {
  content: "\36";
}
.icon-animal-elephant-head-alt:before {
  content: "\37";
}
.icon-animal-elk:before {
  content: "\38";
}
.icon-animal-fish:before {
  content: "\39";
}
.icon-animal-fish-alt-1:before {
  content: "\21";
}
.icon-animal-fish-alt-2:before {
  content: "\22";
}
.icon-animal-fish-alt-3:before {
  content: "\23";
}
.icon-animal-fish-alt-4:before {
  content: "\24";
}
.icon-animal-fox:before {
  content: "\25";
}
.icon-animal-fox-alt:before {
  content: "\26";
}
.icon-animal-frog:before {
  content: "\27";
}
.icon-animal-frog-tracks:before {
  content: "\28";
}
.icon-animal-froggy:before {
  content: "\29";
}
.icon-animal-giraffe:before {
  content: "\2a";
}
.icon-animal-giraffe-alt:before {
  content: "\2b";
}
.icon-animal-goat-head:before {
  content: "\2c";
}
.icon-animal-goat-head-alt-1:before {
  content: "\2d";
}
.icon-animal-goat-head-alt-2:before {
  content: "\2e";
}
.icon-animal-gorilla:before {
  content: "\2f";
}
.icon-animal-hen-tracks:before {
  content: "\3a";
}
.icon-abacus:before {
  content: "\61";
}
.icon-abacus-alt:before {
  content: "\62";
}
.icon-abc:before {
  content: "\63";
}
.icon-addons:before {
  content: "\64";
}
.icon-address-book:before {
  content: "\65";
}
.icon-adjust:before {
  content: "\66";
}
.icon-aids:before {
  content: "\67";
}
.icon-air-balloon:before {
  content: "\68";
}
.icon-airplane:before {
  content: "\69";
}
.icon-airplane-alt:before {
  content: "\6a";
}
.icon-alarm:before {
  content: "\6b";
}
.icon-align-center:before {
  content: "\6c";
}
.icon-align-left:before {
  content: "\6d";
}
.icon-align-right:before {
  content: "\6e";
}
.icon-all-caps:before {
  content: "\6f";
}
.icon-amazon:before {
  content: "\70";
}
.icon-amazon-alt:before {
  content: "\71";
}
.icon-ambulance:before {
  content: "\72";
}
.icon-ambulance-crescent:before {
  content: "\73";
}
.icon-ambulance-cross:before {
  content: "\74";
}
.icon-american-express:before {
  content: "\75";
}
.icon-american-express-alt:before {
  content: "\76";
}
.icon-anchor:before {
  content: "\77";
}
.icon-anchor-alt:before {
  content: "\78";
}
.icon-android-nexus:before {
  content: "\79";
}
.icon-android-tablet:before {
  content: "\7a";
}
.icon-animal-horse-head:before {
  content: "\3b";
}
.icon-animal-horse-head-alt-1:before {
  content: "\3c";
}
.icon-animal-horse-head-alt-2:before {
  content: "\3d";
}
.icon-animal-horse-tracks:before {
  content: "\3e";
}
.icon-animal-jellyfish:before {
  content: "\3f";
}
.icon-animal-kangaroo:before {
  content: "\40";
}
.icon-animal-lemur:before {
  content: "\5b";
}
.icon-animal-lion:before {
  content: "\5d";
}
.icon-animal-lion-alt:before {
  content: "\5e";
}
.icon-animal-lion-head:before {
  content: "\5f";
}
.icon-animal-lion-head-alt:before {
  content: "\60";
}
.icon-animal-monkey:before {
  content: "\7b";
}
.icon-animal-monkey-alt-1:before {
  content: "\7c";
}
.icon-animal-monkey-alt-2:before {
  content: "\7d";
}
.icon-animal-monkey-alt-3:before {
  content: "\7e";
}
.icon-animal-octopus:before {
  content: "\5c";
}
.icon-animal-octopus-alt:before {
  content: "\e000";
}
.icon-animal-owl:before {
  content: "\e001";
}
.icon-animal-panda:before {
  content: "\e002";
}
.icon-animal-panda-alt:before {
  content: "\e003";
}
.icon-animal-panther:before {
  content: "\e004";
}
.icon-animal-parrot:before {
  content: "\e005";
}
.icon-animal-parrot-lip:before {
  content: "\e006";
}
.icon-animal-paw:before {
  content: "\e007";
}
.icon-animal-pelican:before {
  content: "\e008";
}
.icon-animal-penguin:before {
  content: "\e009";
}
.icon-animal-pig:before {
  content: "\e00a";
}
.icon-animal-pig-alt:before {
  content: "\e00b";
}
.icon-animal-pigeon:before {
  content: "\e00c";
}
.icon-animal-pigeon-alt:before {
  content: "\e00d";
}
.icon-animal-pigeons:before {
  content: "\e00e";
}
.icon-animal-rabbit-running:before {
  content: "\e00f";
}
.icon-animal-rat-alt:before {
  content: "\e010";
}
.icon-animal-rhino:before {
  content: "\e011";
}
.icon-animal-rhino-head:before {
  content: "\e012";
}
.icon-animal-rooster:before {
  content: "\e013";
}
.icon-animal-seahorse:before {
  content: "\e014";
}
.icon-animal-seal:before {
  content: "\e015";
}
.icon-animal-shrimp:before {
  content: "\e016";
}
.icon-animal-snail:before {
  content: "\e017";
}
.icon-animal-snail-alt-1:before {
  content: "\e018";
}
.icon-animal-snail-alt-2:before {
  content: "\e019";
}
.icon-animal-snake:before {
  content: "\e01a";
}
.icon-animal-squid:before {
  content: "\e01b";
}
.icon-animal-squirrel:before {
  content: "\e01c";
}
.icon-animal-tiger:before {
  content: "\e01d";
}
.icon-animal-tiger-alt:before {
  content: "\e01e";
}
.icon-animal-turtle:before {
  content: "\e01f";
}
.icon-animal-whale:before {
  content: "\e020";
}
.icon-animal-woodpecker:before {
  content: "\e021";
}
.icon-animal-zebra:before {
  content: "\e022";
}
.icon-apple:before {
  content: "\e023";
}
.icon-apple-pay:before {
  content: "\e024";
}
.icon-apple-pay-alt:before {
  content: "\e025";
}
.icon-apple-watch:before {
  content: "\e026";
}
.icon-arabian-coffee:before {
  content: "\e027";
}
.icon-architecture:before {
  content: "\e028";
}
.icon-architecture-alt:before {
  content: "\e029";
}
.icon-archive:before {
  content: "\e02a";
}
.icon-arrow-down:before {
  content: "\e02b";
}
.icon-arrow-left:before {
  content: "\e02c";
}
.icon-arrow-right:before {
  content: "\e02d";
}
.icon-arrow-up:before {
  content: "\e02e";
}
.icon-artichoke:before {
  content: "\e02f";
}
.icon-articulated-truck:before {
  content: "\e030";
}
.icon-asparagus:before {
  content: "\e031";
}
.icon-at:before {
  content: "\e032";
}
.icon-atom:before {
  content: "\e033";
}
.icon-attachment:before {
  content: "\e034";
}
.icon-audio:before {
  content: "\e035";
}
.icon-autism:before {
  content: "\e036";
}
.icon-auto-mobile:before {
  content: "\e037";
}
.icon-auto-rickshaw:before {
  content: "\e038";
}
.icon-automation:before {
  content: "\e039";
}
.icon-avocado:before {
  content: "\e03a";
}
.icon-award:before {
  content: "\e03b";
}
.icon-baby:before {
  content: "\e03c";
}
.icon-baby-cloth:before {
  content: "\e03d";
}
.icon-baby-food:before {
  content: "\e03e";
}
.icon-baby-milk-bottle:before {
  content: "\e03f";
}
.icon-baby-trolley:before {
  content: "\e040";
}
.icon-back-pack:before {
  content: "\e041";
}
.icon-badge:before {
  content: "\e042";
}
.icon-badminton-birdie:before {
  content: "\e043";
}
.icon-bag:before {
  content: "\e044";
}
.icon-bag-alt:before {
  content: "\e045";
}
.icon-ban:before {
  content: "\e046";
}
.icon-banana:before {
  content: "\e047";
}
.icon-bandage:before {
  content: "\e048";
}
.icon-bank:before {
  content: "\e049";
}
.icon-bank-alt:before {
  content: "\e04a";
}
.icon-bank-transfer:before {
  content: "\e04b";
}
.icon-bank-transfer-alt:before {
  content: "\e04c";
}
.icon-bar-code:before {
  content: "\e04d";
}
.icon-barcode:before {
  content: "\e04e";
}
.icon-barricade:before {
  content: "\e04f";
}
.icon-bars:before {
  content: "\e050";
}
.icon-baseball:before {
  content: "\e051";
}
.icon-baseballer:before {
  content: "\e052";
}
.icon-basket:before {
  content: "\e053";
}
.icon-basketball:before {
  content: "\e054";
}
.icon-basketball-hoop:before {
  content: "\e055";
}
.icon-bathtub:before {
  content: "\e056";
}
.icon-battery-empty:before {
  content: "\e057";
}
.icon-battery-full:before {
  content: "\e058";
}
.icon-battery-half:before {
  content: "\e059";
}
.icon-battery-low:before {
  content: "\e05a";
}
.icon-bbq:before {
  content: "\e05b";
}
.icon-beach:before {
  content: "\e05c";
}
.icon-beach-bed:before {
  content: "\e05d";
}
.icon-beaker:before {
  content: "\e05e";
}
.icon-beans:before {
  content: "\e05f";
}
.icon-bear:before {
  content: "\e060";
}
.icon-beard:before {
  content: "\e061";
}
.icon-bed:before {
  content: "\e062";
}
.icon-bed-patient:before {
  content: "\e063";
}
.icon-beer:before {
  content: "\e064";
}
.icon-bell:before {
  content: "\e065";
}
.icon-bell-alt:before {
  content: "\e066";
}
.icon-bell-pepper-capsicum:before {
  content: "\e067";
}
.icon-beverage:before {
  content: "\e068";
}
.icon-bicycle:before {
  content: "\e069";
}
.icon-bicycle-alt-1:before {
  content: "\e06a";
}
.icon-bicycle-alt-2:before {
  content: "\e06b";
}
.icon-bill:before {
  content: "\e06c";
}
.icon-bill-alt:before {
  content: "\e06d";
}
.icon-billboard:before {
  content: "\e06e";
}
.icon-billiard-ball:before {
  content: "\e06f";
}
.icon-bin:before {
  content: "\e070";
}
.icon-binary:before {
  content: "\e071";
}
.icon-binoculars:before {
  content: "\e072";
}
.icon-bird:before {
  content: "\e073";
}
.icon-bird-wings:before {
  content: "\e074";
}
.icon-birds:before {
  content: "\e075";
}
.icon-birthday-cake:before {
  content: "\e076";
}
.icon-black-board:before {
  content: "\e077";
}
.icon-blind:before {
  content: "\e078";
}
.icon-block-down:before {
  content: "\e079";
}
.icon-block-left:before {
  content: "\e07a";
}
.icon-block-right:before {
  content: "\e07b";
}
.icon-block-up:before {
  content: "\e07c";
}
.icon-blood:before {
  content: "\e07d";
}
.icon-blood-drop:before {
  content: "\e07e";
}
.icon-blood-test:before {
  content: "\e07f";
}
.icon-bluetooth:before {
  content: "\e080";
}
.icon-bold:before {
  content: "\e081";
}
.icon-bolt:before {
  content: "\e082";
}
.icon-bomb:before {
  content: "\e083";
}
.icon-book:before {
  content: "\e084";
}
.icon-book-alt:before {
  content: "\e085";
}
.icon-book-mark:before {
  content: "\e086";
}
.icon-boot:before {
  content: "\e087";
}
.icon-boot-alt-1:before {
  content: "\e088";
}
.icon-boot-alt-2:before {
  content: "\e089";
}
.icon-bow:before {
  content: "\e08a";
}
.icon-bowling:before {
  content: "\e08b";
}
.icon-bowling-alt:before {
  content: "\e08c";
}
.icon-box:before {
  content: "\e08d";
}
.icon-boy:before {
  content: "\e08e";
}
.icon-brain:before {
  content: "\e08f";
}
.icon-brain-alt:before {
  content: "\e090";
}
.icon-brainstorming:before {
  content: "\e091";
}
.icon-braintree:before {
  content: "\e092";
}
.icon-braintree-alt:before {
  content: "\e093";
}
.icon-brand-acer:before {
  content: "\e094";
}
.icon-brand-adidas:before {
  content: "\e095";
}
.icon-brand-adobe:before {
  content: "\e096";
}
.icon-brand-air-new-zealand:before {
  content: "\e097";
}
.icon-brand-airbnb:before {
  content: "\e098";
}
.icon-brand-aircell:before {
  content: "\e099";
}
.icon-brand-airtel:before {
  content: "\e09a";
}
.icon-brand-alcatel:before {
  content: "\e09b";
}
.icon-brand-alibaba:before {
  content: "\e09c";
}
.icon-brand-aliexpress:before {
  content: "\e09d";
}
.icon-brand-alipay:before {
  content: "\e09e";
}
.icon-brand-amazon:before {
  content: "\e09f";
}
.icon-brand-amd:before {
  content: "\e0a0";
}
.icon-brand-american-airlines:before {
  content: "\e0a1";
}
.icon-brand-android:before {
  content: "\e0a2";
}
.icon-brand-android-robot:before {
  content: "\e0a3";
}
.icon-brand-aol:before {
  content: "\e0a4";
}
.icon-brand-apple:before {
  content: "\e0a5";
}
.icon-brand-appstore:before {
  content: "\e0a6";
}
.icon-brand-asus:before {
  content: "\e0a7";
}
.icon-brand-ati:before {
  content: "\e0a8";
}
.icon-brand-att:before {
  content: "\e0a9";
}
.icon-brand-audi:before {
  content: "\e0aa";
}
.icon-brand-axiata:before {
  content: "\e0ab";
}
.icon-brand-bada:before {
  content: "\e0ac";
}
.icon-brand-bbc:before {
  content: "\e0ad";
}
.icon-brand-bing:before {
  content: "\e0ae";
}
.icon-brand-blackberry:before {
  content: "\e0af";
}
.icon-brand-bmw:before {
  content: "\e0b0";
}
.icon-brand-box:before {
  content: "\e0b1";
}
.icon-brand-burger-king:before {
  content: "\e0b2";
}
.icon-brand-business-insider:before {
  content: "\e0b3";
}
.icon-brand-buzzfeed:before {
  content: "\e0b4";
}
.icon-brand-cannon:before {
  content: "\e0b5";
}
.icon-brand-casio:before {
  content: "\e0b6";
}
.icon-brand-china-mobile:before {
  content: "\e0b7";
}
.icon-brand-china-telecom:before {
  content: "\e0b8";
}
.icon-brand-china-unicom:before {
  content: "\e0b9";
}
.icon-brand-cisco:before {
  content: "\e0ba";
}
.icon-brand-citibank:before {
  content: "\e0bb";
}
.icon-brand-cnet:before {
  content: "\e0bc";
}
.icon-brand-cnn:before {
  content: "\e0bd";
}
.icon-brand-cocal-cola:before {
  content: "\e0be";
}
.icon-brand-compaq:before {
  content: "\e0bf";
}
.icon-brand-copy:before {
  content: "\e0c0";
}
.icon-brand-debian:before {
  content: "\e0c1";
}
.icon-brand-delicious:before {
  content: "\e0c2";
}
.icon-brand-dell:before {
  content: "\e0c3";
}
.icon-brand-designbump:before {
  content: "\e0c4";
}
.icon-brand-designfloat:before {
  content: "\e0c5";
}
.icon-brand-disney:before {
  content: "\e0c6";
}
.icon-brand-dodge:before {
  content: "\e0c7";
}
.icon-brand-dove:before {
  content: "\e0c8";
}
.icon-brand-drupal:before {
  content: "\e0c9";
}
.icon-brand-ebay:before {
  content: "\e0ca";
}
.icon-brand-eleven:before {
  content: "\e0cb";
}
.icon-brand-emirates:before {
  content: "\e0cc";
}
.icon-brand-espn:before {
  content: "\e0cd";
}
.icon-brand-etihad-airways:before {
  content: "\e0ce";
}
.icon-brand-etisalat:before {
  content: "\e0cf";
}
.icon-brand-etsy:before {
  content: "\e0d0";
}
.icon-brand-facebook:before {
  content: "\e0d1";
}
.icon-brand-fastrack:before {
  content: "\e0d2";
}
.icon-brand-fedex:before {
  content: "\e0d3";
}
.icon-brand-ferrari:before {
  content: "\e0d4";
}
.icon-brand-fitbit:before {
  content: "\e0d5";
}
.icon-brand-flikr:before {
  content: "\e0d6";
}
.icon-brand-forbes:before {
  content: "\e0d7";
}
.icon-brand-foursquare:before {
  content: "\e0d8";
}
.icon-brand-fox:before {
  content: "\e0d9";
}
.icon-brand-foxconn:before {
  content: "\e0da";
}
.icon-brand-fujitsu:before {
  content: "\e0db";
}
.icon-brand-general-electric:before {
  content: "\e0dc";
}
.icon-brand-gillette:before {
  content: "\e0dd";
}
.icon-brand-gizmodo:before {
  content: "\e0de";
}
.icon-brand-gnome:before {
  content: "\e0df";
}
.icon-brand-google:before {
  content: "\e0e0";
}
.icon-brand-gopro:before {
  content: "\e0e1";
}
.icon-brand-gucci:before {
  content: "\e0e2";
}
.icon-brand-hallmark:before {
  content: "\e0e3";
}
.icon-brand-hi5:before {
  content: "\e0e4";
}
.icon-brand-honda:before {
  content: "\e0e5";
}
.icon-brand-hp:before {
  content: "\e0e6";
}
.icon-brand-hsbc:before {
  content: "\e0e7";
}
.icon-brand-htc:before {
  content: "\e0e8";
}
.icon-brand-huawei:before {
  content: "\e0e9";
}
.icon-brand-hulu:before {
  content: "\e0ea";
}
.icon-brand-hyundai:before {
  content: "\e0eb";
}
.icon-brand-ibm:before {
  content: "\e0ec";
}
.icon-brand-icofont:before {
  content: "\e0ed";
}
.icon-brand-icq:before {
  content: "\e0ee";
}
.icon-brand-ikea:before {
  content: "\e0ef";
}
.icon-brand-imdb:before {
  content: "\e0f0";
}
.icon-brand-indiegogo:before {
  content: "\e0f1";
}
.icon-brand-intel:before {
  content: "\e0f2";
}
.icon-brand-ipair:before {
  content: "\e0f3";
}
.icon-brand-jaguar:before {
  content: "\e0f4";
}
.icon-brand-java:before {
  content: "\e0f5";
}
.icon-brand-joomla:before {
  content: "\e0f6";
}
.icon-brand-joomshaper:before {
  content: "\e0f7";
}
.icon-brand-kickstarter:before {
  content: "\e0f8";
}
.icon-brand-kik:before {
  content: "\e0f9";
}
.icon-brand-lastfm:before {
  content: "\e0fa";
}
.icon-brand-lego:before {
  content: "\e0fb";
}
.icon-brand-lenovo:before {
  content: "\e0fc";
}
.icon-brand-levis:before {
  content: "\e0fd";
}
.icon-brand-lexus:before {
  content: "\e0fe";
}
.icon-brand-lg:before {
  content: "\e0ff";
}
.icon-brand-life-hacker:before {
  content: "\e100";
}
.icon-brand-line-messenger:before {
  content: "\e101";
}
.icon-brand-linkedin:before {
  content: "\e102";
}
.icon-brand-linux:before {
  content: "\e103";
}
.icon-brand-linux-mint:before {
  content: "\e104";
}
.icon-brand-lionix:before {
  content: "\e105";
}
.icon-brand-live-messenger:before {
  content: "\e106";
}
.icon-brand-loreal:before {
  content: "\e107";
}
.icon-brand-louis-vuitton:before {
  content: "\e108";
}
.icon-brand-mac-os:before {
  content: "\e109";
}
.icon-brand-marvel-app:before {
  content: "\e10a";
}
.icon-brand-mashable:before {
  content: "\e10b";
}
.icon-brand-mazda:before {
  content: "\e10c";
}
.icon-brand-mcdonals:before {
  content: "\e10d";
}
.icon-brand-mercedes:before {
  content: "\e10e";
}
.icon-brand-micromax:before {
  content: "\e10f";
}
.icon-brand-microsoft:before {
  content: "\e110";
}
.icon-brand-mobileme:before {
  content: "\e111";
}
.icon-brand-mobily:before {
  content: "\e112";
}
.icon-brand-motorola:before {
  content: "\e113";
}
.icon-brand-msi:before {
  content: "\e114";
}
.icon-brand-mts:before {
  content: "\e115";
}
.icon-brand-myspace:before {
  content: "\e116";
}
.icon-brand-mytv:before {
  content: "\e117";
}
.icon-brand-nasa:before {
  content: "\e118";
}
.icon-brand-natgeo:before {
  content: "\e119";
}
.icon-brand-nbc:before {
  content: "\e11a";
}
.icon-brand-nescafe:before {
  content: "\e11b";
}
.icon-brand-nestle:before {
  content: "\e11c";
}
.icon-brand-netflix:before {
  content: "\e11d";
}
.icon-brand-nexus:before {
  content: "\e11e";
}
.icon-brand-nike:before {
  content: "\e11f";
}
.icon-brand-nokia:before {
  content: "\e120";
}
.icon-brand-nvidia:before {
  content: "\e121";
}
.icon-brand-omega:before {
  content: "\e122";
}
.icon-brand-opensuse:before {
  content: "\e123";
}
.icon-brand-oracle:before {
  content: "\e124";
}
.icon-brand-panasonic:before {
  content: "\e125";
}
.icon-brand-paypal:before {
  content: "\e126";
}
.icon-brand-pepsi:before {
  content: "\e127";
}
.icon-brand-philips:before {
  content: "\e128";
}
.icon-brand-pizza-hut:before {
  content: "\e129";
}
.icon-brand-playstation:before {
  content: "\e12a";
}
.icon-brand-puma:before {
  content: "\e12b";
}
.icon-brand-qatar-air:before {
  content: "\e12c";
}
.icon-brand-qvc:before {
  content: "\e12d";
}
.icon-brand-readernaut:before {
  content: "\e12e";
}
.icon-brand-redbull:before {
  content: "\e12f";
}
.icon-brand-reebok:before {
  content: "\e130";
}
.icon-brand-reuters:before {
  content: "\e131";
}
.icon-brand-samsung:before {
  content: "\e132";
}
.icon-brand-sap:before {
  content: "\e133";
}
.icon-brand-saudia-airlines:before {
  content: "\e134";
}
.icon-brand-scribd:before {
  content: "\e135";
}
.icon-brand-shell:before {
  content: "\e136";
}
.icon-brand-siemens:before {
  content: "\e137";
}
.icon-brand-sk-telecom:before {
  content: "\e138";
}
.icon-brand-slideshare:before {
  content: "\e139";
}
.icon-brand-smashing-magazine:before {
  content: "\e13a";
}
.icon-brand-snapchat:before {
  content: "\e13b";
}
.icon-brand-sony:before {
  content: "\e13c";
}
.icon-brand-sony-ericsson:before {
  content: "\e13d";
}
.icon-brand-soundcloud:before {
  content: "\e13e";
}
.icon-brand-sprint:before {
  content: "\e13f";
}
.icon-brand-squidoo:before {
  content: "\e140";
}
.icon-brand-starbucks:before {
  content: "\e141";
}
.icon-brand-stc:before {
  content: "\e142";
}
.icon-brand-steam:before {
  content: "\e143";
}
.icon-brand-suzuki:before {
  content: "\e144";
}
.icon-brand-symbian:before {
  content: "\e145";
}
.icon-brand-t-mobile:before {
  content: "\e146";
}
.icon-brand-tango:before {
  content: "\e147";
}
.icon-brand-target:before {
  content: "\e148";
}
.icon-brand-tata-indicom:before {
  content: "\e149";
}
.icon-brand-techcrunch:before {
  content: "\e14a";
}
.icon-brand-telenor:before {
  content: "\e14b";
}
.icon-brand-teliasonera:before {
  content: "\e14c";
}
.icon-brand-tesla:before {
  content: "\e14d";
}
.icon-brand-the-verge:before {
  content: "\e14e";
}
.icon-brand-thenextweb:before {
  content: "\e14f";
}
.icon-brand-toshiba:before {
  content: "\e150";
}
.icon-brand-toyota:before {
  content: "\e151";
}
.icon-brand-tribenet:before {
  content: "\e152";
}
.icon-brand-ubuntu:before {
  content: "\e153";
}
.icon-brand-unilever:before {
  content: "\e154";
}
.icon-brand-vaio:before {
  content: "\e155";
}
.icon-brand-verizon:before {
  content: "\e156";
}
.icon-brand-viber:before {
  content: "\e157";
}
.icon-brand-vodafone:before {
  content: "\e158";
}
.icon-brand-volkswagen:before {
  content: "\e159";
}
.icon-brand-walmart:before {
  content: "\e15a";
}
.icon-brand-warnerbros:before {
  content: "\e15b";
}
.icon-brand-whatsapp:before {
  content: "\e15c";
}
.icon-brand-wikipedia:before {
  content: "\e15d";
}
.icon-brand-windows:before {
  content: "\e15e";
}
.icon-brand-wire:before {
  content: "\e15f";
}
.icon-brand-wordpress:before {
  content: "\e160";
}
.icon-brand-xiaomi:before {
  content: "\e161";
}
.icon-brand-yahoobuzz:before {
  content: "\e162";
}
.icon-brand-yamaha:before {
  content: "\e163";
}
.icon-brand-youtube:before {
  content: "\e164";
}
.icon-brand-zain:before {
  content: "\e165";
}
.icon-bread:before {
  content: "\e166";
}
.icon-breakdown:before {
  content: "\e167";
}
.icon-bricks:before {
  content: "\e168";
}
.icon-briefcase:before {
  content: "\e169";
}
.icon-briefcase-alt-1:before {
  content: "\e16a";
}
.icon-briefcase-alt-2:before {
  content: "\e16b";
}
.icon-broccoli:before {
  content: "\e16c";
}
.icon-broken:before {
  content: "\e16d";
}
.icon-brush:before {
  content: "\e16e";
}
.icon-bubble-down:before {
  content: "\e16f";
}
.icon-bubble-left:before {
  content: "\e170";
}
.icon-bubble-right:before {
  content: "\e171";
}
.icon-bubble-up:before {
  content: "\e172";
}
.icon-bucket:before {
  content: "\e173";
}
.icon-bucket1:before {
  content: "\e174";
}
.icon-bucket2:before {
  content: "\e175";
}
.icon-bug:before {
  content: "\e176";
}
.icon-building:before {
  content: "\e177";
}
.icon-building-alt:before {
  content: "\e178";
}
.icon-bull-dozer:before {
  content: "\e179";
}
.icon-bullet:before {
  content: "\e17a";
}
.icon-bullhorn:before {
  content: "\e17b";
}
.icon-bullseye:before {
  content: "\e17c";
}
.icon-burger:before {
  content: "\e17d";
}
.icon-burglar:before {
  content: "\e17e";
}
.icon-bus:before {
  content: "\e17f";
}
.icon-bus-alt-1:before {
  content: "\e180";
}
.icon-bus-alt-2:before {
  content: "\e181";
}
.icon-bus-alt-3:before {
  content: "\e182";
}
.icon-business-man:before {
  content: "\e183";
}
.icon-business-man-alt-1:before {
  content: "\e184";
}
.icon-business-man-alt-2:before {
  content: "\e185";
}
.icon-business-man-alt-3:before {
  content: "\e186";
}
.icon-businessman:before {
  content: "\e187";
}
.icon-businesswoman:before {
  content: "\e188";
}
.icon-butterfly:before {
  content: "\e189";
}
.icon-butterfly-alt:before {
  content: "\e18a";
}
.icon-cab:before {
  content: "\e18b";
}
.icon-cabbage:before {
  content: "\e18c";
}
.icon-cable-car:before {
  content: "\e18d";
}
.icon-calculations:before {
  content: "\e18e";
}
.icon-calculator:before {
  content: "\e18f";
}
.icon-calculator-alt-1:before {
  content: "\e190";
}
.icon-calculator-alt-2:before {
  content: "\e191";
}
.icon-calendar:before {
  content: "\e192";
}
.icon-camera:before {
  content: "\e193";
}
.icon-camera-alt:before {
  content: "\e194";
}
.icon-camping-vest:before {
  content: "\e195";
}
.icon-candy:before {
  content: "\e196";
}
.icon-cannon-firing:before {
  content: "\e197";
}
.icon-canoe:before {
  content: "\e198";
}
.icon-capsule:before {
  content: "\e199";
}
.icon-car:before {
  content: "\e19a";
}
.icon-car-alt-1:before {
  content: "\e19b";
}
.icon-car-alt-2:before {
  content: "\e19c";
}
.icon-car-alt-3:before {
  content: "\e19d";
}
.icon-car-alt-4:before {
  content: "\e19e";
}
.icon-card:before {
  content: "\e19f";
}
.icon-caret-down:before {
  content: "\e1a0";
}
.icon-caret-left:before {
  content: "\e1a1";
}
.icon-caret-right:before {
  content: "\e1a2";
}
.icon-caret-up:before {
  content: "\e1a3";
}
.icon-carrot:before {
  content: "\e1a4";
}
.icon-cart:before {
  content: "\e1a5";
}
.icon-cart-alt:before {
  content: "\e1a6";
}
.icon-cash-on-delivery:before {
  content: "\e1a7";
}
.icon-cash-on-delivery-alt:before {
  content: "\e1a8";
}
.icon-cassette:before {
  content: "\e1a9";
}
.icon-cassette-player:before {
  content: "\e1aa";
}
.icon-castle:before {
  content: "\e1ab";
}
.icon-cauli-flower:before {
  content: "\e1ac";
}
.icon-cc:before {
  content: "\e1ad";
}
.icon-cc-camera:before {
  content: "\e1ae";
}
.icon-celsius:before {
  content: "\e1af";
}
.icon-cement-mix:before {
  content: "\e1b0";
}
.icon-cement-mixer:before {
  content: "\e1b1";
}
.icon-certificate:before {
  content: "\e1b2";
}
.icon-certificate-alt-1:before {
  content: "\e1b3";
}
.icon-certificate-alt-2:before {
  content: "\e1b4";
}
.icon-chair:before {
  content: "\e1b5";
}
.icon-charging:before {
  content: "\e1b6";
}
.icon-chart-arrows-axis:before {
  content: "\e1b7";
}
.icon-chart-bar-graph:before {
  content: "\e1b8";
}
.icon-chart-flow:before {
  content: "\e1b9";
}
.icon-chart-flow-alt-1:before {
  content: "\e1ba";
}
.icon-chart-flow-alt-2:before {
  content: "\e1bb";
}
.icon-chart-histogram:before {
  content: "\e1bc";
}
.icon-chart-histogram-alt:before {
  content: "\e1bd";
}
.icon-chart-line:before {
  content: "\e1be";
}
.icon-chart-line-alt:before {
  content: "\e1bf";
}
.icon-chart-pie:before {
  content: "\e1c0";
}
.icon-chart-pie-alt:before {
  content: "\e1c1";
}
.icon-chart-radar-graph:before {
  content: "\e1c2";
}
.icon-chat:before {
  content: "\e1c3";
}
.icon-check:before {
  content: "\e1c4";
}
.icon-check-alt:before {
  content: "\e1c5";
}
.icon-check-circled:before {
  content: "\e1c6";
}
.icon-checked:before {
  content: "\e1c7";
}
.icon-checkout:before {
  content: "\e1c8";
}
.icon-checkout-alt:before {
  content: "\e1c9";
}
.icon-cheer-leader:before {
  content: "\e1ca";
}
.icon-cheese:before {
  content: "\e1cb";
}
.icon-chef:before {
  content: "\e1cc";
}
.icon-cherry:before {
  content: "\e1cd";
}
.icon-chicken:before {
  content: "\e1ce";
}
.icon-chicken-fry:before {
  content: "\e1cf";
}
.icon-children-care:before {
  content: "\e1d0";
}
.icon-circle-ruler:before {
  content: "\e1d1";
}
.icon-circle-ruler-alt:before {
  content: "\e1d2";
}
.icon-circled-down:before {
  content: "\e1d3";
}
.icon-circled-left:before {
  content: "\e1d4";
}
.icon-circled-right:before {
  content: "\e1d5";
}
.icon-circled-up:before {
  content: "\e1d6";
}
.icon-circuit:before {
  content: "\e1d7";
}
.icon-climbing:before {
  content: "\e1d8";
}
.icon-clip:before {
  content: "\e1d9";
}
.icon-clip-board:before {
  content: "\e1da";
}
.icon-clock-time:before {
  content: "\e1db";
}
.icon-close:before {
  content: "\e1dc";
}
.icon-close-circled:before {
  content: "\e1dd";
}
.icon-close-line:before {
  content: "\e1de";
}
.icon-close-line-circled:before {
  content: "\e1df";
}
.icon-close-line-squared:before {
  content: "\e1e0";
}
.icon-close-line-squared-alt:before {
  content: "\e1e1";
}
.icon-close-squared:before {
  content: "\e1e2";
}
.icon-close-squared-alt:before {
  content: "\e1e3";
}
.icon-cloud:before {
  content: "\e1e4";
}
.icon-cloud-download:before {
  content: "\e1e5";
}
.icon-cloud-refresh:before {
  content: "\e1e6";
}
.icon-cloud-upload:before {
  content: "\e1e7";
}
.icon-clouds:before {
  content: "\e1e8";
}
.icon-cloudy:before {
  content: "\e1e9";
}
.icon-cocktail:before {
  content: "\e1ea";
}
.icon-coconut:before {
  content: "\e1eb";
}
.icon-coconut-alt:before {
  content: "\e1ec";
}
.icon-code:before {
  content: "\e1ed";
}
.icon-code-alt:before {
  content: "\e1ee";
}
.icon-code-not-allowed:before {
  content: "\e1ef";
}
.icon-coffee-alt:before {
  content: "\e1f0";
}
.icon-coffee-cup:before {
  content: "\e1f1";
}
.icon-coffee-mug:before {
  content: "\e1f2";
}
.icon-coffee-pot:before {
  content: "\e1f3";
}
.icon-coins:before {
  content: "\e1f4";
}
.icon-cola:before {
  content: "\e1f5";
}
.icon-collapse:before {
  content: "\e1f6";
}
.icon-color-bucket:before {
  content: "\e1f7";
}
.icon-color-picker:before {
  content: "\e1f8";
}
.icon-comment:before {
  content: "\e1f9";
}
.icon-company:before {
  content: "\e1fa";
}
.icon-compass:before {
  content: "\e1fb";
}
.icon-compass-alt:before {
  content: "\e1fc";
}
.icon-compass-alt-1:before {
  content: "\e1fd";
}
.icon-compass-alt-2:before {
  content: "\e1fe";
}
.icon-compass-alt-3:before {
  content: "\e1ff";
}
.icon-compass-alt-4:before {
  content: "\e200";
}
.icon-computer:before {
  content: "\e201";
}
.icon-concrete-mixer:before {
  content: "\e202";
}
.icon-connection:before {
  content: "\e203";
}
.icon-console:before {
  content: "\e204";
}
.icon-contact-add:before {
  content: "\e205";
}
.icon-contacts:before {
  content: "\e206";
}
.icon-contrast:before {
  content: "\e207";
}
.icon-cop:before {
  content: "\e208";
}
.icon-cop-badge:before {
  content: "\e209";
}
.icon-copy:before {
  content: "\e20a";
}
.icon-copy-alt:before {
  content: "\e20b";
}
.icon-copy-black:before {
  content: "\e20c";
}
.icon-copyright:before {
  content: "\e20d";
}
.icon-corn:before {
  content: "\e20e";
}
.icon-corner:before {
  content: "\e20f";
}
.icon-court:before {
  content: "\e210";
}
.icon-court-hammer:before {
  content: "\e211";
}
.icon-credit-card:before {
  content: "\e212";
}
.icon-croissant:before {
  content: "\e213";
}
.icon-crop:before {
  content: "\e214";
}
.icon-crop-plant:before {
  content: "\e215";
}
.icon-crown:before {
  content: "\e216";
}
.icon-crutches:before {
  content: "\e217";
}
.icon-cube:before {
  content: "\e218";
}
.icon-cubes:before {
  content: "\e219";
}
.icon-cucumber:before {
  content: "\e21a";
}
.icon-culinary:before {
  content: "\e21b";
}
.icon-cup-cake:before {
  content: "\e21c";
}
.icon-cur-afghani:before {
  content: "\e21d";
}
.icon-cur-afghani-false:before {
  content: "\e21e";
}
.icon-cur-afghani-minus:before {
  content: "\e21f";
}
.icon-cur-afghani-plus:before {
  content: "\e220";
}
.icon-cur-afghani-true:before {
  content: "\e221";
}
.icon-cur-baht:before {
  content: "\e222";
}
.icon-cur-baht-false:before {
  content: "\e223";
}
.icon-cur-baht-minus:before {
  content: "\e224";
}
.icon-cur-baht-plus:before {
  content: "\e225";
}
.icon-cur-baht-true:before {
  content: "\e226";
}
.icon-cur-bitcoin:before {
  content: "\e227";
}
.icon-cur-bitcoin-false:before {
  content: "\e228";
}
.icon-cur-bitcoin-minus:before {
  content: "\e229";
}
.icon-cur-bitcoin-plus:before {
  content: "\e22a";
}
.icon-cur-bitcoin-true:before {
  content: "\e22b";
}
.icon-cur-dollar:before {
  content: "\e22c";
}
.icon-cur-dollar-flase:before {
  content: "\e22d";
}
.icon-cur-dollar-minus:before {
  content: "\e22e";
}
.icon-cur-dollar-plus:before {
  content: "\e22f";
}
.icon-cur-dollar-true:before {
  content: "\e230";
}
.icon-cur-dong:before {
  content: "\e231";
}
.icon-cur-dong-false:before {
  content: "\e232";
}
.icon-cur-dong-minus:before {
  content: "\e233";
}
.icon-cur-dong-plus:before {
  content: "\e234";
}
.icon-cur-dong-true:before {
  content: "\e235";
}
.icon-cur-euro:before {
  content: "\e236";
}
.icon-cur-euro-false:before {
  content: "\e237";
}
.icon-cur-euro-minus:before {
  content: "\e238";
}
.icon-cur-euro-plus:before {
  content: "\e239";
}
.icon-cur-euro-true:before {
  content: "\e23a";
}
.icon-cur-frank:before {
  content: "\e23b";
}
.icon-cur-frank-false:before {
  content: "\e23c";
}
.icon-cur-frank-minus:before {
  content: "\e23d";
}
.icon-cur-frank-plus:before {
  content: "\e23e";
}
.icon-cur-frank-true:before {
  content: "\e23f";
}
.icon-cur-hryvnia:before {
  content: "\e240";
}
.icon-cur-hryvnia-false:before {
  content: "\e241";
}
.icon-cur-hryvnia-minus:before {
  content: "\e242";
}
.icon-cur-hryvnia-plus:before {
  content: "\e243";
}
.icon-cur-hryvnia-true:before {
  content: "\e244";
}
.icon-cur-lira:before {
  content: "\e245";
}
.icon-cur-lira-false:before {
  content: "\e246";
}
.icon-cur-lira-minus:before {
  content: "\e247";
}
.icon-cur-lira-plus:before {
  content: "\e248";
}
.icon-cur-lira-true:before {
  content: "\e249";
}
.icon-cur-peseta:before {
  content: "\e24a";
}
.icon-cur-peseta-false:before {
  content: "\e24b";
}
.icon-cur-peseta-minus:before {
  content: "\e24c";
}
.icon-cur-peseta-plus:before {
  content: "\e24d";
}
.icon-cur-peseta-true:before {
  content: "\e24e";
}
.icon-cur-peso:before {
  content: "\e24f";
}
.icon-cur-peso-false:before {
  content: "\e250";
}
.icon-cur-peso-minus:before {
  content: "\e251";
}
.icon-cur-peso-plus:before {
  content: "\e252";
}
.icon-cur-peso-true:before {
  content: "\e253";
}
.icon-cur-pound:before {
  content: "\e254";
}
.icon-cur-pound-false:before {
  content: "\e255";
}
.icon-cur-pound-minus:before {
  content: "\e256";
}
.icon-cur-pound-plus:before {
  content: "\e257";
}
.icon-cur-pound-true:before {
  content: "\e258";
}
.icon-cur-renminbi:before {
  content: "\e259";
}
.icon-cur-renminbi-false:before {
  content: "\e25a";
}
.icon-cur-renminbi-minus:before {
  content: "\e25b";
}
.icon-cur-renminbi-plus:before {
  content: "\e25c";
}
.icon-cur-renminbi-true:before {
  content: "\e25d";
}
.icon-cur-riyal:before {
  content: "\e25e";
}
.icon-cur-riyal-false:before {
  content: "\e25f";
}
.icon-cur-riyal-minus:before {
  content: "\e260";
}
.icon-cur-riyal-plus:before {
  content: "\e261";
}
.icon-cur-riyal-true:before {
  content: "\e262";
}
.icon-cur-rouble:before {
  content: "\e263";
}
.icon-cur-rouble-false:before {
  content: "\e264";
}
.icon-cur-rouble-minus:before {
  content: "\e265";
}
.icon-cur-rouble-plus:before {
  content: "\e266";
}
.icon-cur-rouble-true:before {
  content: "\e267";
}
.icon-cur-rupee:before {
  content: "\e268";
}
.icon-cur-rupee-false:before {
  content: "\e269";
}
.icon-cur-rupee-minus:before {
  content: "\e26a";
}
.icon-cur-rupee-plus:before {
  content: "\e26b";
}
.icon-cur-rupee-true:before {
  content: "\e26c";
}
.icon-cur-taka:before {
  content: "\e26d";
}
.icon-cur-taka-false:before {
  content: "\e26e";
}
.icon-cur-taka-minus:before {
  content: "\e26f";
}
.icon-cur-taka-plus:before {
  content: "\e270";
}
.icon-cur-taka-true:before {
  content: "\e271";
}
.icon-cur-turkish-lira:before {
  content: "\e272";
}
.icon-cur-turkish-lira-false:before {
  content: "\e273";
}
.icon-cur-turkish-lira-minus:before {
  content: "\e274";
}
.icon-cur-turkish-lira-plus:before {
  content: "\e275";
}
.icon-cur-turkish-lira-true:before {
  content: "\e276";
}
.icon-cur-won:before {
  content: "\e277";
}
.icon-cur-won-false:before {
  content: "\e278";
}
.icon-cur-won-minus:before {
  content: "\e279";
}
.icon-cur-won-plus:before {
  content: "\e27a";
}
.icon-cur-won-true:before {
  content: "\e27b";
}
.icon-cur-yen:before {
  content: "\e27c";
}
.icon-cur-yen-false:before {
  content: "\e27d";
}
.icon-cur-yen-minus:before {
  content: "\e27e";
}
.icon-cur-yen-plus:before {
  content: "\e27f";
}
.icon-cur-yen-true:before {
  content: "\e280";
}
.icon-cursor-drag:before {
  content: "\e281";
}
.icon-curved-double-left:before {
  content: "\e282";
}
.icon-curved-double-right:before {
  content: "\e283";
}
.icon-curved-down:before {
  content: "\e284";
}
.icon-curved-left:before {
  content: "\e285";
}
.icon-curved-right:before {
  content: "\e286";
}
.icon-curved-up:before {
  content: "\e287";
}
.icon-cut:before {
  content: "\e288";
}
.icon-cycling:before {
  content: "\e289";
}
.icon-cyclist:before {
  content: "\e28a";
}
.icon-danger-zone:before {
  content: "\e28b";
}
.icon-dart:before {
  content: "\e28c";
}
.icon-dashboard:before {
  content: "\e28d";
}
.icon-dashboard-web:before {
  content: "\e28e";
}
.icon-data:before {
  content: "\e28f";
}
.icon-database:before {
  content: "\e290";
}
.icon-database-add:before {
  content: "\e291";
}
.icon-database-locked:before {
  content: "\e292";
}
.icon-database-remove:before {
  content: "\e293";
}
.icon-deal:before {
  content: "\e294";
}
.icon-degrees:before {
  content: "\e295";
}
.icon-degrees-alt-1:before {
  content: "\e296";
}
.icon-degrees-alt-2:before {
  content: "\e297";
}
.icon-delete:before {
  content: "\e298";
}
.icon-delete-alt:before {
  content: "\e299";
}
.icon-delivery-time:before {
  content: "\e29a";
}
.icon-diamond:before {
  content: "\e29b";
}
.icon-dice:before {
  content: "\e29c";
}
.icon-dice-alt:before {
  content: "\e29d";
}
.icon-diners-club:before {
  content: "\e29e";
}
.icon-diners-club-alt-1:before {
  content: "\e29f";
}
.icon-diners-club-alt-2:before {
  content: "\e2a0";
}
.icon-diners-club-alt-3:before {
  content: "\e2a1";
}
.icon-dining-table:before {
  content: "\e2a2";
}
.icon-direction-sign:before {
  content: "\e2a3";
}
.icon-disability-race:before {
  content: "\e2a4";
}
.icon-disabled:before {
  content: "\e2a5";
}
.icon-disc:before {
  content: "\e2a6";
}
.icon-discover:before {
  content: "\e2a7";
}
.icon-discover-alt:before {
  content: "\e2a8";
}
.icon-diskette:before {
  content: "\e2a9";
}
.icon-diving-goggle:before {
  content: "\e2aa";
}
.icon-dna:before {
  content: "\e2ab";
}
.icon-dna-alt-1:before {
  content: "\e2ac";
}
.icon-dna-alt-2:before {
  content: "\e2ad";
}
.icon-doctor:before {
  content: "\e2ae";
}
.icon-doctor-alt:before {
  content: "\e2af";
}
.icon-document-folder:before {
  content: "\e2b0";
}
.icon-document-search:before {
  content: "\e2b1";
}
.icon-donut:before {
  content: "\e2b2";
}
.icon-dotted-down:before {
  content: "\e2b3";
}
.icon-dotted-left:before {
  content: "\e2b4";
}
.icon-dotted-right:before {
  content: "\e2b5";
}
.icon-dotted-up:before {
  content: "\e2b6";
}
.icon-double-left:before {
  content: "\e2b7";
}
.icon-double-right:before {
  content: "\e2b8";
}
.icon-download:before {
  content: "\e2b9";
}
.icon-download-alt:before {
  content: "\e2ba";
}
.icon-downloaded:before {
  content: "\e2bb";
}
.icon-drag:before {
  content: "\e2bc";
}
.icon-drag1:before {
  content: "\e2bd";
}
.icon-drag2:before {
  content: "\e2be";
}
.icon-drag3:before {
  content: "\e2bf";
}
.icon-drill:before {
  content: "\e2c0";
}
.icon-drug:before {
  content: "\e2c1";
}
.icon-drug-pack:before {
  content: "\e2c2";
}
.icon-drwaing-tablet:before {
  content: "\e2c3";
}
.icon-dumbbell:before {
  content: "\e2c4";
}
.icon-dumbbell-alt:before {
  content: "\e2c5";
}
.icon-dust:before {
  content: "\e2c6";
}
.icon-earphone:before {
  content: "\e2c7";
}
.icon-earth:before {
  content: "\e2c8";
}
.icon-ebook:before {
  content: "\e2c9";
}
.icon-eclipse:before {
  content: "\e2ca";
}
.icon-eco-energy:before {
  content: "\e2cb";
}
.icon-eco-environmen:before {
  content: "\e2cc";
}
.icon-edit:before {
  content: "\e2cd";
}
.icon-edit-alt:before {
  content: "\e2ce";
}
.icon-education:before {
  content: "\e2cf";
}
.icon-egg-plant:before {
  content: "\e2d0";
}
.icon-egg-poached:before {
  content: "\e2d1";
}
.icon-eject:before {
  content: "\e2d2";
}
.icon-electron:before {
  content: "\e2d3";
}
.icon-email:before {
  content: "\e2d4";
}
.icon-emo-angry:before {
  content: "\e2d5";
}
.icon-emo-astonished:before {
  content: "\e2d6";
}
.icon-emo-confounded:before {
  content: "\e2d7";
}
.icon-emo-confused:before {
  content: "\e2d8";
}
.icon-emo-crying:before {
  content: "\e2d9";
}
.icon-emo-dizzy:before {
  content: "\e2da";
}
.icon-emo-expressionless:before {
  content: "\e2db";
}
.icon-emo-heart-eyes:before {
  content: "\e2dc";
}
.icon-emo-laughing:before {
  content: "\e2dd";
}
.icon-emo-nerd-smile:before {
  content: "\e2de";
}
.icon-emo-open-mouth:before {
  content: "\e2df";
}
.icon-emo-rage:before {
  content: "\e2e0";
}
.icon-emo-rolling-eyes:before {
  content: "\e2e1";
}
.icon-emo-sad:before {
  content: "\e2e2";
}
.icon-emo-simple-smile:before {
  content: "\e2e3";
}
.icon-emo-slightly-smile:before {
  content: "\e2e4";
}
.icon-emo-smirk:before {
  content: "\e2e5";
}
.icon-emo-stuck-out-tongue:before {
  content: "\e2e6";
}
.icon-emo-wink-smile:before {
  content: "\e2e7";
}
.icon-emo-worried:before {
  content: "\e2e8";
}
.icon-energy-air:before {
  content: "\e2e9";
}
.icon-energy-oil:before {
  content: "\e2ea";
}
.icon-energy-savings:before {
  content: "\e2eb";
}
.icon-energy-solar:before {
  content: "\e2ec";
}
.icon-energy-water:before {
  content: "\e2ed";
}
.icon-engineer:before {
  content: "\e2ee";
}
.icon-envelope:before {
  content: "\e2ef";
}
.icon-envelope-open:before {
  content: "\e2f0";
}
.icon-eraser:before {
  content: "\e2f1";
}
.icon-eraser-alt:before {
  content: "\e2f2";
}
.icon-error:before {
  content: "\e2f3";
}
.icon-eway:before {
  content: "\e2f4";
}
.icon-eway-alt:before {
  content: "\e2f5";
}
.icon-excavator:before {
  content: "\e2f6";
}
.icon-exchange:before {
  content: "\e2f7";
}
.icon-exclamation:before {
  content: "\e2f8";
}
.icon-exclamation-circle:before {
  content: "\e2f9";
}
.icon-exclamation-square:before {
  content: "\e2fa";
}
.icon-exclamation-tringle:before {
  content: "\e2fb";
}
.icon-exit:before {
  content: "\e2fc";
}
.icon-expand:before {
  content: "\e2fd";
}
.icon-expand-alt:before {
  content: "\e2fe";
}
.icon-external:before {
  content: "\e2ff";
}
.icon-external-link:before {
  content: "\e300";
}
.icon-eye:before {
  content: "\e301";
}
.icon-eye-alt:before {
  content: "\e302";
}
.icon-eye-blocked:before {
  content: "\e303";
}
.icon-eye-dropper:before {
  content: "\e304";
}
.icon-fahrenheit:before {
  content: "\e305";
}
.icon-farmer:before {
  content: "\e306";
}
.icon-farmer1:before {
  content: "\e307";
}
.icon-fast-delivery:before {
  content: "\e308";
}
.icon-fast-food:before {
  content: "\e309";
}
.icon-favourite:before {
  content: "\e30a";
}
.icon-fax:before {
  content: "\e30b";
}
.icon-female:before {
  content: "\e30c";
}
.icon-field:before {
  content: "\e30d";
}
.icon-field-alt:before {
  content: "\e30e";
}
.icon-file:before {
  content: "\e30f";
}
.icon-file-alt:before {
  content: "\e310";
}
.icon-file-audio:before {
  content: "\e311";
}
.icon-file-avi-mp4:before {
  content: "\e312";
}
.icon-file-bmp:before {
  content: "\e313";
}
.icon-file-code:before {
  content: "\e314";
}
.icon-file-css:before {
  content: "\e315";
}
.icon-file-document:before {
  content: "\e316";
}
.icon-file-eps:before {
  content: "\e317";
}
.icon-file-excel:before {
  content: "\e318";
}
.icon-file-exe:before {
  content: "\e319";
}
.icon-file-file:before {
  content: "\e31a";
}
.icon-file-flv:before {
  content: "\e31b";
}
.icon-file-gif:before {
  content: "\e31c";
}
.icon-file-html5:before {
  content: "\e31d";
}
.icon-file-image:before {
  content: "\e31e";
}
.icon-file-iso:before {
  content: "\e31f";
}
.icon-file-java:before {
  content: "\e320";
}
.icon-file-javascript:before {
  content: "\e321";
}
.icon-file-jpg:before {
  content: "\e322";
}
.icon-file-midi:before {
  content: "\e323";
}
.icon-file-mov:before {
  content: "\e324";
}
.icon-file-mp3:before {
  content: "\e325";
}
.icon-file-pdf:before {
  content: "\e326";
}
.icon-file-php:before {
  content: "\e327";
}
.icon-file-png:before {
  content: "\e328";
}
.icon-file-powerpoint:before {
  content: "\e329";
}
.icon-file-presentation:before {
  content: "\e32a";
}
.icon-file-psb:before {
  content: "\e32b";
}
.icon-file-psd:before {
  content: "\e32c";
}
.icon-file-python:before {
  content: "\e32d";
}
.icon-file-ruby:before {
  content: "\e32e";
}
.icon-file-spreadsheet:before {
  content: "\e32f";
}
.icon-file-sql:before {
  content: "\e330";
}
.icon-file-svg:before {
  content: "\e331";
}
.icon-file-text:before {
  content: "\e332";
}
.icon-file-tiff:before {
  content: "\e333";
}
.icon-file-video:before {
  content: "\e334";
}
.icon-file-wave:before {
  content: "\e335";
}
.icon-file-wmv:before {
  content: "\e336";
}
.icon-file-word:before {
  content: "\e337";
}
.icon-file-zip:before {
  content: "\e338";
}
.icon-files:before {
  content: "\e339";
}
.icon-film:before {
  content: "\e33a";
}
.icon-filter:before {
  content: "\e33b";
}
.icon-finger-print:before {
  content: "\e33c";
}
.icon-fire:before {
  content: "\e33d";
}
.icon-fire-alt:before {
  content: "\e33e";
}
.icon-fire-burn:before {
  content: "\e33f";
}
.icon-fire-extinguisher:before {
  content: "\e340";
}
.icon-fire-extinguisher-alt:before {
  content: "\e341";
}
.icon-fire-truck:before {
  content: "\e342";
}
.icon-fire-truck-alt:before {
  content: "\e343";
}
.icon-first-aid:before {
  content: "\e344";
}
.icon-first-aid-alt:before {
  content: "\e345";
}
.icon-fish:before {
  content: "\e346";
}
.icon-fix-tools:before {
  content: "\e347";
}
.icon-flag:before {
  content: "\e348";
}
.icon-flag-alt-1:before {
  content: "\e349";
}
.icon-flag-alt-2:before {
  content: "\e34a";
}
.icon-flame-torch:before {
  content: "\e34b";
}
.icon-flash:before {
  content: "\e34c";
}
.icon-flash-drive:before {
  content: "\e34d";
}
.icon-flash-light:before {
  content: "\e34e";
}
.icon-flask:before {
  content: "\e34f";
}
.icon-flora:before {
  content: "\e350";
}
.icon-flora-flower:before {
  content: "\e351";
}
.icon-focus:before {
  content: "\e352";
}
.icon-folder:before {
  content: "\e353";
}
.icon-folder-open:before {
  content: "\e354";
}
.icon-folder-search:before {
  content: "\e355";
}
.icon-font:before {
  content: "\e356";
}
.icon-food-basket:before {
  content: "\e357";
}
.icon-food-cart:before {
  content: "\e358";
}
.icon-foot-print:before {
  content: "\e359";
}
.icon-football:before {
  content: "\e35a";
}
.icon-football-alt:before {
  content: "\e35b";
}
.icon-football-american:before {
  content: "\e35c";
}
.icon-forest-fire:before {
  content: "\e35d";
}
.icon-fork-and-knife:before {
  content: "\e35e";
}
.icon-fork-lift:before {
  content: "\e35f";
}
.icon-forward:before {
  content: "\e360";
}
.icon-foul:before {
  content: "\e361";
}
.icon-fountain-pen:before {
  content: "\e362";
}
.icon-free-delivery:before {
  content: "\e363";
}
.icon-french-fries:before {
  content: "\e364";
}
.icon-fresh-juice:before {
  content: "\e365";
}
.icon-fruits:before {
  content: "\e366";
}
.icon-full-night:before {
  content: "\e367";
}
.icon-full-sunny:before {
  content: "\e368";
}
.icon-funky-man:before {
  content: "\e369";
}
.icon-game:before {
  content: "\e36a";
}
.icon-game-console:before {
  content: "\e36b";
}
.icon-game-control:before {
  content: "\e36c";
}
.icon-game-pad:before {
  content: "\e36d";
}
.icon-garbage:before {
  content: "\e36e";
}
.icon-gavel:before {
  content: "\e36f";
}
.icon-gear:before {
  content: "\e370";
}
.icon-gears:before {
  content: "\e371";
}
.icon-gift:before {
  content: "\e372";
}
.icon-gift-box:before {
  content: "\e373";
}
.icon-girl:before {
  content: "\e374";
}
.icon-girl-alt:before {
  content: "\e375";
}
.icon-glass:before {
  content: "\e376";
}
.icon-globe:before {
  content: "\e377";
}
.icon-globe-alt:before {
  content: "\e378";
}
.icon-glue-oil:before {
  content: "\e379";
}
.icon-goal:before {
  content: "\e37a";
}
.icon-goal-keeper:before {
  content: "\e37b";
}
.icon-golden-ratio:before {
  content: "\e37c";
}
.icon-golf:before {
  content: "\e37d";
}
.icon-golf-alt:before {
  content: "\e37e";
}
.icon-golf-bag:before {
  content: "\e37f";
}
.icon-golf-cart:before {
  content: "\e380";
}
.icon-golf-field:before {
  content: "\e381";
}
.icon-golfer:before {
  content: "\e382";
}
.icon-google-wallet:before {
  content: "\e383";
}
.icon-google-wallet-alt-1:before {
  content: "\e384";
}
.icon-google-wallet-alt-2:before {
  content: "\e385";
}
.icon-google-wallet-alt-3:before {
  content: "\e386";
}
.icon-graduate:before {
  content: "\e387";
}
.icon-graduate-alt:before {
  content: "\e388";
}
.icon-graffiti:before {
  content: "\e389";
}
.icon-grapes:before {
  content: "\e38a";
}
.icon-grocery:before {
  content: "\e38b";
}
.icon-group:before {
  content: "\e38c";
}
.icon-group-students:before {
  content: "\e38d";
}
.icon-growth:before {
  content: "\e38e";
}
.icon-guiter:before {
  content: "\e38f";
}
.icon-gym:before {
  content: "\e390";
}
.icon-gym-alt-1:before {
  content: "\e391";
}
.icon-gym-alt-2:before {
  content: "\e392";
}
.icon-gym-alt-3:before {
  content: "\e393";
}
.icon-hail:before {
  content: "\e394";
}
.icon-hail-night:before {
  content: "\e395";
}
.icon-hail-rainy:before {
  content: "\e396";
}
.icon-hail-rainy-night:before {
  content: "\e397";
}
.icon-hail-rainy-sunny:before {
  content: "\e398";
}
.icon-hail-sunny:before {
  content: "\e399";
}
.icon-hail-thunder:before {
  content: "\e39a";
}
.icon-hail-thunder-night:before {
  content: "\e39b";
}
.icon-hail-thunder-sunny:before {
  content: "\e39c";
}
.icon-halloween-pumpkin:before {
  content: "\e39d";
}
.icon-hammer:before {
  content: "\e39e";
}
.icon-hammer-alt:before {
  content: "\e39f";
}
.icon-hand:before {
  content: "\e3a0";
}
.icon-hand-down:before {
  content: "\e3a1";
}
.icon-hand-drag:before {
  content: "\e3a2";
}
.icon-hand-drag1:before {
  content: "\e3a3";
}
.icon-hand-drag2:before {
  content: "\e3a4";
}
.icon-hand-drawn-alt-down:before {
  content: "\e3a5";
}
.icon-hand-drawn-alt-left:before {
  content: "\e3a6";
}
.icon-hand-drawn-alt-right:before {
  content: "\e3a7";
}
.icon-hand-drawn-alt-up:before {
  content: "\e3a8";
}
.icon-hand-drawn-down:before {
  content: "\e3a9";
}
.icon-hand-drawn-left:before {
  content: "\e3aa";
}
.icon-hand-drawn-right:before {
  content: "\e3ab";
}
.icon-hand-drawn-up:before {
  content: "\e3ac";
}
.icon-hand-grippers:before {
  content: "\e3ad";
}
.icon-hand-left:before {
  content: "\e3ae";
}
.icon-hand-power:before {
  content: "\e3af";
}
.icon-hand-right:before {
  content: "\e3b0";
}
.icon-hand-thunder:before {
  content: "\e3b1";
}
.icon-hand-up:before {
  content: "\e3b2";
}
.icon-handcuff:before {
  content: "\e3b3";
}
.icon-handcuff-alt:before {
  content: "\e3b4";
}
.icon-hanger:before {
  content: "\e3b5";
}
.icon-hard-disk:before {
  content: "\e3b6";
}
.icon-hat:before {
  content: "\e3b7";
}
.icon-hat-alt:before {
  content: "\e3b8";
}
.icon-header:before {
  content: "\e3b9";
}
.icon-headphone:before {
  content: "\e3ba";
}
.icon-headphone-alt:before {
  content: "\e3bb";
}
.icon-headphone-alt-1:before {
  content: "\e3bc";
}
.icon-headphone-alt-2:before {
  content: "\e3bd";
}
.icon-headphone-alt-3:before {
  content: "\e3be";
}
.icon-heart:before {
  content: "\e3bf";
}
.icon-heart-alt:before {
  content: "\e3c0";
}
.icon-heart-beat:before {
  content: "\e3c1";
}
.icon-heart-beat-alt:before {
  content: "\e3c2";
}
.icon-heartbeat:before {
  content: "\e3c3";
}
.icon-helicopter:before {
  content: "\e3c4";
}
.icon-helmet:before {
  content: "\e3c5";
}
.icon-help-robot:before {
  content: "\e3c6";
}
.icon-herbal:before {
  content: "\e3c7";
}
.icon-hill:before {
  content: "\e3c8";
}
.icon-hill-night:before {
  content: "\e3c9";
}
.icon-hill-side:before {
  content: "\e3ca";
}
.icon-hill-sunny:before {
  content: "\e3cb";
}
.icon-history:before {
  content: "\e3cc";
}
.icon-hockey:before {
  content: "\e3cd";
}
.icon-hockey-alt:before {
  content: "\e3ce";
}
.icon-holding-hands:before {
  content: "\e3cf";
}
.icon-home:before {
  content: "\e3d0";
}
.icon-home-search:before {
  content: "\e3d1";
}
.icon-honey:before {
  content: "\e3d2";
}
.icon-horn:before {
  content: "\e3d3";
}
.icon-hospital:before {
  content: "\e3d4";
}
.icon-hot-dog:before {
  content: "\e3d5";
}
.icon-hotel:before {
  content: "\e3d6";
}
.icon-hotel-alt:before {
  content: "\e3d7";
}
.icon-hotel-boy:before {
  content: "\e3d8";
}
.icon-hotel-boy-alt:before {
  content: "\e3d9";
}
.icon-hour-glass:before {
  content: "\e3da";
}
.icon-htc-one:before {
  content: "\e3db";
}
.icon-hurricane:before {
  content: "\e3dc";
}
.icon-ice-cream:before {
  content: "\e3dd";
}
.icon-ice-cream-alt:before {
  content: "\e3de";
}
.icon-ice-skate:before {
  content: "\e3df";
}
.icon-icu:before {
  content: "\e3e0";
}
.icon-id:before {
  content: "\e3e1";
}
.icon-id-card:before {
  content: "\e3e2";
}
.icon-idea:before {
  content: "\e3e3";
}
.icon-imac:before {
  content: "\e3e4";
}
.icon-image:before {
  content: "\e3e5";
}
.icon-inbox:before {
  content: "\e3e6";
}
.icon-indent:before {
  content: "\e3e7";
}
.icon-industries:before {
  content: "\e3e8";
}
.icon-industries-alt-1:before {
  content: "\e3e9";
}
.icon-industries-alt-2:before {
  content: "\e3ea";
}
.icon-industries-alt-3:before {
  content: "\e3eb";
}
.icon-industries-alt-4:before {
  content: "\e3ec";
}
.icon-industries-alt-5:before {
  content: "\e3ed";
}
.icon-infant-nipple:before {
  content: "\e3ee";
}
.icon-infinite:before {
  content: "\e3ef";
}
.icon-info:before {
  content: "\e3f0";
}
.icon-info-circle:before {
  content: "\e3f1";
}
.icon-info-square:before {
  content: "\e3f2";
}
.icon-injection-syringe:before {
  content: "\e3f3";
}
.icon-institution:before {
  content: "\e3f4";
}
.icon-instrument:before {
  content: "\e3f5";
}
.icon-interface:before {
  content: "\e3f6";
}
.icon-investigation:before {
  content: "\e3f7";
}
.icon-investigator:before {
  content: "\e3f8";
}
.icon-invisible:before {
  content: "\e3f9";
}
.icon-ipad-touch:before {
  content: "\e3fa";
}
.icon-iphone:before {
  content: "\e3fb";
}
.icon-ipod-nano:before {
  content: "\e3fc";
}
.icon-ipod-touch:before {
  content: "\e3fd";
}
.icon-island:before {
  content: "\e3fe";
}
.icon-island-alt:before {
  content: "\e3ff";
}
.icon-italic:before {
  content: "\e400";
}
.icon-italic-alt:before {
  content: "\e401";
}
.icon-jacket:before {
  content: "\e402";
}
.icon-jail:before {
  content: "\e403";
}
.icon-jar:before {
  content: "\e404";
}
.icon-jcb:before {
  content: "\e405";
}
.icon-jcb-alt:before {
  content: "\e406";
}
.icon-jersey:before {
  content: "\e407";
}
.icon-jersey-alt:before {
  content: "\e408";
}
.icon-jewlery:before {
  content: "\e409";
}
.icon-job-search:before {
  content: "\e40a";
}
.icon-judge:before {
  content: "\e40b";
}
.icon-jumping:before {
  content: "\e40c";
}
.icon-justify-all:before {
  content: "\e40d";
}
.icon-justify-center:before {
  content: "\e40e";
}
.icon-justify-left:before {
  content: "\e40f";
}
.icon-justify-right:before {
  content: "\e410";
}
.icon-karate:before {
  content: "\e411";
}
.icon-ketchup:before {
  content: "\e412";
}
.icon-key:before {
  content: "\e413";
}
.icon-key-hole:before {
  content: "\e414";
}
.icon-keyboard:before {
  content: "\e415";
}
.icon-keyboard-alt:before {
  content: "\e416";
}
.icon-keyboard-wireless:before {
  content: "\e417";
}
.icon-kick:before {
  content: "\e418";
}
.icon-kid:before {
  content: "\e419";
}
.icon-kids-scooter:before {
  content: "\e41a";
}
.icon-king-crown:before {
  content: "\e41b";
}
.icon-king-monster:before {
  content: "\e41c";
}
.icon-kiwi:before {
  content: "\e41d";
}
.icon-label:before {
  content: "\e41e";
}
.icon-laboratory:before {
  content: "\e41f";
}
.icon-labour:before {
  content: "\e420";
}
.icon-lamp:before {
  content: "\e421";
}
.icon-lamp-light:before {
  content: "\e422";
}
.icon-laptop:before {
  content: "\e423";
}
.icon-laptop-alt:before {
  content: "\e424";
}
.icon-law:before {
  content: "\e425";
}
.icon-law-alt-1:before {
  content: "\e426";
}
.icon-law-alt-2:before {
  content: "\e427";
}
.icon-law-alt-3:before {
  content: "\e428";
}
.icon-law-book:before {
  content: "\e429";
}
.icon-law-document:before {
  content: "\e42a";
}
.icon-lawyer:before {
  content: "\e42b";
}
.icon-lawyer-alt-1:before {
  content: "\e42c";
}
.icon-lawyer-alt-2:before {
  content: "\e42d";
}
.icon-layered-cake:before {
  content: "\e42e";
}
.icon-layers:before {
  content: "\e42f";
}
.icon-layout:before {
  content: "\e430";
}
.icon-leaf:before {
  content: "\e431";
}
.icon-leaflet:before {
  content: "\e432";
}
.icon-learn:before {
  content: "\e433";
}
.icon-leg:before {
  content: "\e434";
}
.icon-legal:before {
  content: "\e435";
}
.icon-lego:before {
  content: "\e436";
}
.icon-lemon:before {
  content: "\e437";
}
.icon-lemon-alt:before {
  content: "\e438";
}
.icon-lens:before {
  content: "\e439";
}
.icon-letter:before {
  content: "\e43a";
}
.icon-letterbox:before {
  content: "\e43b";
}
.icon-library:before {
  content: "\e43c";
}
.icon-license:before {
  content: "\e43d";
}
.icon-life-bouy:before {
  content: "\e43e";
}
.icon-life-buoy:before {
  content: "\e43f";
}
.icon-life-jacket:before {
  content: "\e440";
}
.icon-life-ring:before {
  content: "\e441";
}
.icon-light-bulb:before {
  content: "\e442";
}
.icon-lighter:before {
  content: "\e443";
}
.icon-lightning-ray:before {
  content: "\e444";
}
.icon-like:before {
  content: "\e445";
}
.icon-line-block-down:before {
  content: "\e446";
}
.icon-line-block-left:before {
  content: "\e447";
}
.icon-line-block-right:before {
  content: "\e448";
}
.icon-line-block-up:before {
  content: "\e449";
}
.icon-line-height:before {
  content: "\e44a";
}
.icon-link:before {
  content: "\e44b";
}
.icon-link-alt:before {
  content: "\e44c";
}
.icon-list:before {
  content: "\e44d";
}
.icon-listening:before {
  content: "\e44e";
}
.icon-listine-dots:before {
  content: "\e44f";
}
.icon-listing-box:before {
  content: "\e450";
}
.icon-listing-number:before {
  content: "\e451";
}
.icon-live-support:before {
  content: "\e452";
}
.icon-lobster:before {
  content: "\e453";
}
.icon-location-arrow:before {
  content: "\e454";
}
.icon-location-pin:before {
  content: "\e455";
}
.icon-lock:before {
  content: "\e456";
}
.icon-login:before {
  content: "\e457";
}
.icon-logout:before {
  content: "\e458";
}
.icon-lollipop:before {
  content: "\e459";
}
.icon-long-arrow-down:before {
  content: "\e45a";
}
.icon-long-arrow-left:before {
  content: "\e45b";
}
.icon-long-arrow-right:before {
  content: "\e45c";
}
.icon-long-arrow-up:before {
  content: "\e45d";
}
.icon-long-drive:before {
  content: "\e45e";
}
.icon-look:before {
  content: "\e45f";
}
.icon-loop:before {
  content: "\e460";
}
.icon-love:before {
  content: "\e461";
}
.icon-luggage:before {
  content: "\e462";
}
.icon-lunch:before {
  content: "\e463";
}
.icon-lungs:before {
  content: "\e464";
}
.icon-macbook:before {
  content: "\e465";
}
.icon-maestro:before {
  content: "\e466";
}
.icon-maestro-alt:before {
  content: "\e467";
}
.icon-magic:before {
  content: "\e468";
}
.icon-magic-alt:before {
  content: "\e469";
}
.icon-magic-mouse:before {
  content: "\e46a";
}
.icon-magician-hat:before {
  content: "\e46b";
}
.icon-magnet:before {
  content: "\e46c";
}
.icon-mail:before {
  content: "\e46d";
}
.icon-mail-box:before {
  content: "\e46e";
}
.icon-male:before {
  content: "\e46f";
}
.icon-man-in-glasses:before {
  content: "\e470";
}
.icon-mango:before {
  content: "\e471";
}
.icon-map:before {
  content: "\e472";
}
.icon-map-pins:before {
  content: "\e473";
}
.icon-map-search:before {
  content: "\e474";
}
.icon-marker:before {
  content: "\e475";
}
.icon-marker-alt-1:before {
  content: "\e476";
}
.icon-marker-alt-2:before {
  content: "\e477";
}
.icon-marker-alt-3:before {
  content: "\e478";
}
.icon-mastercard:before {
  content: "\e479";
}
.icon-mastercard-alt:before {
  content: "\e47a";
}
.icon-match-review:before {
  content: "\e47b";
}
.icon-math:before {
  content: "\e47c";
}
.icon-mathematical:before {
  content: "\e47d";
}
.icon-mathematical-alt-1:before {
  content: "\e47e";
}
.icon-mathematical-alt-2:before {
  content: "\e47f";
}
.icon-maximize:before {
  content: "\e480";
}
.icon-measure:before {
  content: "\e481";
}
.icon-medal:before {
  content: "\e482";
}
.icon-medal-alt:before {
  content: "\e483";
}
.icon-medical:before {
  content: "\e484";
}
.icon-medical-sign:before {
  content: "\e485";
}
.icon-medical-sign-alt:before {
  content: "\e486";
}
.icon-medicine:before {
  content: "\e487";
}
.icon-meeting-add:before {
  content: "\e488";
}
.icon-mega-phone:before {
  content: "\e489";
}
.icon-megaphone:before {
  content: "\e48a";
}
.icon-megaphone-alt:before {
  content: "\e48b";
}
.icon-memorial:before {
  content: "\e48c";
}
.icon-memory-card:before {
  content: "\e48d";
}
.icon-meteor:before {
  content: "\e48e";
}
.icon-mic:before {
  content: "\e48f";
}
.icon-mic-mute:before {
  content: "\e490";
}
.icon-micro-chip:before {
  content: "\e491";
}
.icon-microphone:before {
  content: "\e492";
}
.icon-microphone-alt:before {
  content: "\e493";
}
.icon-microscope:before {
  content: "\e494";
}
.icon-microscope-alt:before {
  content: "\e495";
}
.icon-military:before {
  content: "\e496";
}
.icon-milk:before {
  content: "\e497";
}
.icon-mill:before {
  content: "\e498";
}
.icon-mining:before {
  content: "\e499";
}
.icon-minus:before {
  content: "\e49a";
}
.icon-minus-circle:before {
  content: "\e49b";
}
.icon-minus-square:before {
  content: "\e49c";
}
.icon-mobile-phone:before {
  content: "\e49d";
}
.icon-molecule:before {
  content: "\e49e";
}
.icon-money:before {
  content: "\e49f";
}
.icon-money-bag:before {
  content: "\e4a0";
}
.icon-monitor:before {
  content: "\e4a1";
}
.icon-moon:before {
  content: "\e4a2";
}
.icon-mop:before {
  content: "\e4a3";
}
.icon-motor-bike:before {
  content: "\e4a4";
}
.icon-motor-bike-alt:before {
  content: "\e4a5";
}
.icon-motor-biker:before {
  content: "\e4a6";
}
.icon-mouse:before {
  content: "\e4a7";
}
.icon-movie:before {
  content: "\e4a8";
}
.icon-mp3-player:before {
  content: "\e4a9";
}
.icon-muffin:before {
  content: "\e4aa";
}
.icon-multimedia:before {
  content: "\e4ab";
}
.icon-muscle:before {
  content: "\e4ac";
}
.icon-muscle-alt:before {
  content: "\e4ad";
}
.icon-mushroom:before {
  content: "\e4ae";
}
.icon-music:before {
  content: "\e4af";
}
.icon-music-alt:before {
  content: "\e4b0";
}
.icon-music-disk:before {
  content: "\e4b1";
}
.icon-music-note:before {
  content: "\e4b2";
}
.icon-music-notes:before {
  content: "\e4b3";
}
.icon-mustache:before {
  content: "\e4b4";
}
.icon-mute-volume:before {
  content: "\e4b5";
}
.icon-native-american:before {
  content: "\e4b6";
}
.icon-navigation:before {
  content: "\e4b7";
}
.icon-navigation-menu:before {
  content: "\e4b8";
}
.icon-network:before {
  content: "\e4b9";
}
.icon-network-tower:before {
  content: "\e4ba";
}
.icon-news:before {
  content: "\e4bb";
}
.icon-newspaper:before {
  content: "\e4bc";
}
.icon-night:before {
  content: "\e4bd";
}
.icon-nintendo:before {
  content: "\e4be";
}
.icon-no-smoking:before {
  content: "\e4bf";
}
.icon-noodles:before {
  content: "\e4c0";
}
.icon-not-allowed:before {
  content: "\e4c1";
}
.icon-notebook:before {
  content: "\e4c2";
}
.icon-notepad:before {
  content: "\e4c3";
}
.icon-notification:before {
  content: "\e4c4";
}
.icon-numbered:before {
  content: "\e4c5";
}
.icon-nurse:before {
  content: "\e4c6";
}
.icon-nurse-alt:before {
  content: "\e4c7";
}
.icon-nursing-home:before {
  content: "\e4c8";
}
.icon-offside:before {
  content: "\e4c9";
}
.icon-oil-truck:before {
  content: "\e4ca";
}
.icon-olympic:before {
  content: "\e4cb";
}
.icon-olympic-logo:before {
  content: "\e4cc";
}
.icon-onion:before {
  content: "\e4cd";
}
.icon-open-eye:before {
  content: "\e4ce";
}
.icon-operation-theater:before {
  content: "\e4cf";
}
.icon-opposite:before {
  content: "\e4d0";
}
.icon-optic:before {
  content: "\e4d1";
}
.icon-options:before {
  content: "\e4d2";
}
.icon-orange:before {
  content: "\e4d3";
}
.icon-order:before {
  content: "\e4d4";
}
.icon-outdent:before {
  content: "\e4d5";
}
.icon-owl-look:before {
  content: "\e4d6";
}
.icon-package:before {
  content: "\e4d7";
}
.icon-padding:before {
  content: "\e4d8";
}
.icon-page:before {
  content: "\e4d9";
}
.icon-paint:before {
  content: "\e4da";
}
.icon-paint-brush:before {
  content: "\e4db";
}
.icon-paper:before {
  content: "\e4dc";
}
.icon-paper-clip:before {
  content: "\e4dd";
}
.icon-paper-plane:before {
  content: "\e4de";
}
.icon-paperclip:before {
  content: "\e4df";
}
.icon-papers:before {
  content: "\e4e0";
}
.icon-paragraph:before {
  content: "\e4e1";
}
.icon-paralysis-disability:before {
  content: "\e4e2";
}
.icon-pause:before {
  content: "\e4e3";
}
.icon-paw:before {
  content: "\e4e4";
}
.icon-pay:before {
  content: "\e4e5";
}
.icon-payoneer:before {
  content: "\e4e6";
}
.icon-payoneer-alt:before {
  content: "\e4e7";
}
.icon-paypal:before {
  content: "\e4e8";
}
.icon-paypal-alt:before {
  content: "\e4e9";
}
.icon-pear:before {
  content: "\e4ea";
}
.icon-peas:before {
  content: "\e4eb";
}
.icon-pen:before {
  content: "\e4ec";
}
.icon-pen-alt-1:before {
  content: "\e4ed";
}
.icon-pen-alt-2:before {
  content: "\e4ee";
}
.icon-pen-alt-3:before {
  content: "\e4ef";
}
.icon-pen-alt-4:before {
  content: "\e4f0";
}
.icon-pen-holder:before {
  content: "\e4f1";
}
.icon-pen-holder-alt-1:before {
  content: "\e4f2";
}
.icon-pen-nib:before {
  content: "\e4f3";
}
.icon-penalty-card:before {
  content: "\e4f4";
}
.icon-pencil:before {
  content: "\e4f5";
}
.icon-pencil-alt-1:before {
  content: "\e4f6";
}
.icon-pencil-alt-2:before {
  content: "\e4f7";
}
.icon-pencil-alt-3:before {
  content: "\e4f8";
}
.icon-pencil-alt-4:before {
  content: "\e4f9";
}
.icon-pencil-alt-5:before {
  content: "\e4fa";
}
.icon-penguin-linux:before {
  content: "\e4fb";
}
.icon-people:before {
  content: "\e4fc";
}
.icon-pepper:before {
  content: "\e4fd";
}
.icon-pestle:before {
  content: "\e4fe";
}
.icon-phoenix:before {
  content: "\e4ff";
}
.icon-phone:before {
  content: "\e500";
}
.icon-phone-circle:before {
  content: "\e501";
}
.icon-picture:before {
  content: "\e502";
}
.icon-pie:before {
  content: "\e503";
}
.icon-pie-alt:before {
  content: "\e504";
}
.icon-pie-chart:before {
  content: "\e505";
}
.icon-pills:before {
  content: "\e506";
}
.icon-pin:before {
  content: "\e507";
}
.icon-pine:before {
  content: "\e508";
}
.icon-pineapple:before {
  content: "\e509";
}
.icon-pistol:before {
  content: "\e50a";
}
.icon-pixels:before {
  content: "\e50b";
}
.icon-pizza:before {
  content: "\e50c";
}
.icon-pizza-slice:before {
  content: "\e50d";
}
.icon-plane-ticket:before {
  content: "\e50e";
}
.icon-plant:before {
  content: "\e50f";
}
.icon-play:before {
  content: "\e510";
}
.icon-play-alt-1:before {
  content: "\e511";
}
.icon-play-alt-2:before {
  content: "\e512";
}
.icon-play-alt-3:before {
  content: "\e513";
}
.icon-play-pause:before {
  content: "\e514";
}
.icon-playstation:before {
  content: "\e515";
}
.icon-plugin:before {
  content: "\e516";
}
.icon-plus:before {
  content: "\e517";
}
.icon-plus-circle:before {
  content: "\e518";
}
.icon-plus-square:before {
  content: "\e519";
}
.icon-police:before {
  content: "\e51a";
}
.icon-police-badge:before {
  content: "\e51b";
}
.icon-police-cap:before {
  content: "\e51c";
}
.icon-police-car:before {
  content: "\e51d";
}
.icon-police-car-alt-1:before {
  content: "\e51e";
}
.icon-police-car-alt-2:before {
  content: "\e51f";
}
.icon-police-hat:before {
  content: "\e520";
}
.icon-police-van:before {
  content: "\e521";
}
.icon-pollution:before {
  content: "\e522";
}
.icon-polygonal:before {
  content: "\e523";
}
.icon-popcorn:before {
  content: "\e524";
}
.icon-potato:before {
  content: "\e525";
}
.icon-power:before {
  content: "\e526";
}
.icon-power-zone:before {
  content: "\e527";
}
.icon-prescription:before {
  content: "\e528";
}
.icon-presentation:before {
  content: "\e529";
}
.icon-presentation-alt:before {
  content: "\e52a";
}
.icon-price:before {
  content: "\e52b";
}
.icon-print:before {
  content: "\e52c";
}
.icon-printer:before {
  content: "\e52d";
}
.icon-protect:before {
  content: "\e52e";
}
.icon-psvita:before {
  content: "\e52f";
}
.icon-pulse:before {
  content: "\e530";
}
.icon-pumpkin:before {
  content: "\e531";
}
.icon-puzzle:before {
  content: "\e532";
}
.icon-qr-code:before {
  content: "\e533";
}
.icon-queen:before {
  content: "\e534";
}
.icon-queen-crown:before {
  content: "\e535";
}
.icon-question:before {
  content: "\e536";
}
.icon-question-circle:before {
  content: "\e537";
}
.icon-question-square:before {
  content: "\e538";
}
.icon-quill-pen:before {
  content: "\e539";
}
.icon-quote-left:before {
  content: "\e53a";
}
.icon-quote-right:before {
  content: "\e53b";
}
.icon-racer:before {
  content: "\e53c";
}
.icon-racing-car:before {
  content: "\e53d";
}
.icon-racing-flag:before {
  content: "\e53e";
}
.icon-racing-flag-alt:before {
  content: "\e53f";
}
.icon-racings-wheel:before {
  content: "\e540";
}
.icon-raddish:before {
  content: "\e541";
}
.icon-radio:before {
  content: "\e542";
}
.icon-radio-active:before {
  content: "\e543";
}
.icon-radio-mic:before {
  content: "\e544";
}
.icon-rainy:before {
  content: "\e545";
}
.icon-rainy-night:before {
  content: "\e546";
}
.icon-rainy-sunny:before {
  content: "\e547";
}
.icon-rainy-thunder:before {
  content: "\e548";
}
.icon-random:before {
  content: "\e549";
}
.icon-read-book:before {
  content: "\e54a";
}
.icon-read-book-alt:before {
  content: "\e54b";
}
.icon-record:before {
  content: "\e54c";
}
.icon-recycle:before {
  content: "\e54d";
}
.icon-recycle-alt:before {
  content: "\e54e";
}
.icon-recycling-man:before {
  content: "\e54f";
}
.icon-redo:before {
  content: "\e550";
}
.icon-referee:before {
  content: "\e551";
}
.icon-refree-jersey:before {
  content: "\e552";
}
.icon-refresh:before {
  content: "\e553";
}
.icon-refrigerator:before {
  content: "\e554";
}
.icon-repair:before {
  content: "\e555";
}
.icon-reply:before {
  content: "\e556";
}
.icon-reply-all:before {
  content: "\e557";
}
.icon-resize:before {
  content: "\e558";
}
.icon-responsive:before {
  content: "\e559";
}
.icon-restaurant:before {
  content: "\e55a";
}
.icon-restaurant-menu:before {
  content: "\e55b";
}
.icon-restaurant-search:before {
  content: "\e55c";
}
.icon-result:before {
  content: "\e55d";
}
.icon-retro-music-disk:before {
  content: "\e55e";
}
.icon-retweet:before {
  content: "\e55f";
}
.icon-rewind:before {
  content: "\e560";
}
.icon-rickshaw:before {
  content: "\e561";
}
.icon-road:before {
  content: "\e562";
}
.icon-robot:before {
  content: "\e563";
}
.icon-robot-face:before {
  content: "\e564";
}
.icon-rocket:before {
  content: "\e565";
}
.icon-rocket-alt-1:before {
  content: "\e566";
}
.icon-rocket-alt-2:before {
  content: "\e567";
}
.icon-rotation:before {
  content: "\e568";
}
.icon-rounded-collapse:before {
  content: "\e569";
}
.icon-rounded-double-left:before {
  content: "\e56a";
}
.icon-rounded-double-right:before {
  content: "\e56b";
}
.icon-rounded-down:before {
  content: "\e56c";
}
.icon-rounded-expand:before {
  content: "\e56d";
}
.icon-rounded-left:before {
  content: "\e56e";
}
.icon-rounded-left-down:before {
  content: "\e56f";
}
.icon-rounded-left-up:before {
  content: "\e570";
}
.icon-rounded-right:before {
  content: "\e571";
}
.icon-rounded-right-down:before {
  content: "\e572";
}
.icon-rounded-right-up:before {
  content: "\e573";
}
.icon-rounded-up:before {
  content: "\e574";
}
.icon-royal:before {
  content: "\e575";
}
.icon-rss-feed:before {
  content: "\e576";
}
.icon-rugby:before {
  content: "\e577";
}
.icon-rugby-ball:before {
  content: "\e578";
}
.icon-rugby-player:before {
  content: "\e579";
}
.icon-ruler:before {
  content: "\e57a";
}
.icon-ruler-alt-1:before {
  content: "\e57b";
}
.icon-ruler-alt-2:before {
  content: "\e57c";
}
.icon-ruler-compass:before {
  content: "\e57d";
}
.icon-ruler-compass-alt:before {
  content: "\e57e";
}
.icon-ruler-pencil:before {
  content: "\e57f";
}
.icon-ruler-pencil-alt-1:before {
  content: "\e580";
}
.icon-ruler-pencil-alt-2:before {
  content: "\e581";
}
.icon-rulers:before {
  content: "\e582";
}
.icon-rulers-alt:before {
  content: "\e583";
}
.icon-runner:before {
  content: "\e584";
}
.icon-runner-alt-1:before {
  content: "\e585";
}
.icon-runner-alt-2:before {
  content: "\e586";
}
.icon-safety:before {
  content: "\e587";
}
.icon-safety-hat:before {
  content: "\e588";
}
.icon-safety-hat-light:before {
  content: "\e589";
}
.icon-safety-pin:before {
  content: "\e58a";
}
.icon-sage:before {
  content: "\e58b";
}
.icon-sage-alt:before {
  content: "\e58c";
}
.icon-sail-boat:before {
  content: "\e58d";
}
.icon-sail-boat-alt-1:before {
  content: "\e58e";
}
.icon-sail-boat-alt-2:before {
  content: "\e58f";
}
.icon-sale-discount:before {
  content: "\e590";
}
.icon-salt-and-pepper:before {
  content: "\e591";
}
.icon-samsung-galaxy:before {
  content: "\e592";
}
.icon-sand-clock:before {
  content: "\e593";
}
.icon-sandals-female:before {
  content: "\e594";
}
.icon-sandals-male:before {
  content: "\e595";
}
.icon-sandwich:before {
  content: "\e596";
}
.icon-satellite:before {
  content: "\e597";
}
.icon-sausage:before {
  content: "\e598";
}
.icon-save:before {
  content: "\e599";
}
.icon-saw:before {
  content: "\e59a";
}
.icon-scales:before {
  content: "\e59b";
}
.icon-school-bag:before {
  content: "\e59c";
}
.icon-school-bus:before {
  content: "\e59d";
}
.icon-scooter:before {
  content: "\e59e";
}
.icon-score-board:before {
  content: "\e59f";
}
.icon-screw-driver:before {
  content: "\e5a0";
}
.icon-scroll-bubble-down:before {
  content: "\e5a1";
}
.icon-scroll-bubble-left:before {
  content: "\e5a2";
}
.icon-scroll-bubble-right:before {
  content: "\e5a3";
}
.icon-scroll-bubble-up:before {
  content: "\e5a4";
}
.icon-scroll-double-down:before {
  content: "\e5a5";
}
.icon-scroll-double-left:before {
  content: "\e5a6";
}
.icon-scroll-double-right:before {
  content: "\e5a7";
}
.icon-scroll-double-up:before {
  content: "\e5a8";
}
.icon-scroll-down:before {
  content: "\e5a9";
}
.icon-scroll-left:before {
  content: "\e5aa";
}
.icon-scroll-long-down:before {
  content: "\e5ab";
}
.icon-scroll-long-left:before {
  content: "\e5ac";
}
.icon-scroll-long-right:before {
  content: "\e5ad";
}
.icon-scroll-long-up:before {
  content: "\e5ae";
}
.icon-scroll-right:before {
  content: "\e5af";
}
.icon-scroll-up:before {
  content: "\e5b0";
}
.icon-sea-plane:before {
  content: "\e5b1";
}
.icon-search:before {
  content: "\e5b2";
}
.icon-search-alt-1:before {
  content: "\e5b3";
}
.icon-search-alt-2:before {
  content: "\e5b4";
}
.icon-send-mail:before {
  content: "\e5b5";
}
.icon-server:before {
  content: "\e5b6";
}
.icon-settings:before {
  content: "\e5b7";
}
.icon-settings-alt:before {
  content: "\e5b8";
}
.icon-share:before {
  content: "\e5b9";
}
.icon-share-alt:before {
  content: "\e5ba";
}
.icon-share-boxed:before {
  content: "\e5bb";
}
.icon-shield:before {
  content: "\e5bc";
}
.icon-shield-alt:before {
  content: "\e5bd";
}
.icon-ship:before {
  content: "\e5be";
}
.icon-ship-alt:before {
  content: "\e5bf";
}
.icon-ship-wheel:before {
  content: "\e5c0";
}
.icon-shopping-cart:before {
  content: "\e5c1";
}
.icon-showy-night-hail:before {
  content: "\e5c2";
}
.icon-shrimp:before {
  content: "\e5c3";
}
.icon-sign-in:before {
  content: "\e5c4";
}
.icon-sign-out:before {
  content: "\e5c5";
}
.icon-signal:before {
  content: "\e5c6";
}
.icon-simple-down:before {
  content: "\e5c7";
}
.icon-simple-left:before {
  content: "\e5c8";
}
.icon-simple-left-down:before {
  content: "\e5c9";
}
.icon-simple-left-up:before {
  content: "\e5ca";
}
.icon-simple-right:before {
  content: "\e5cb";
}
.icon-simple-right-down:before {
  content: "\e5cc";
}
.icon-simple-right-up:before {
  content: "\e5cd";
}
.icon-simple-up:before {
  content: "\e5ce";
}
.icon-site-map:before {
  content: "\e5cf";
}
.icon-skiing-man:before {
  content: "\e5d0";
}
.icon-skrill:before {
  content: "\e5d1";
}
.icon-skrill-alt:before {
  content: "\e5d2";
}
.icon-skull-danger:before {
  content: "\e5d3";
}
.icon-skull-face:before {
  content: "\e5d4";
}
.icon-skydiving-goggles:before {
  content: "\e5d5";
}
.icon-small-cap:before {
  content: "\e5d6";
}
.icon-smart-phone:before {
  content: "\e5d7";
}
.icon-snail:before {
  content: "\e5d8";
}
.icon-snow:before {
  content: "\e5d9";
}
.icon-snow-alt:before {
  content: "\e5da";
}
.icon-snow-flake:before {
  content: "\e5db";
}
.icon-snow-mobile:before {
  content: "\e5dc";
}
.icon-snow-temp:before {
  content: "\e5dd";
}
.icon-snowmobile:before {
  content: "\e5de";
}
.icon-snowy:before {
  content: "\e5df";
}
.icon-snowy-hail:before {
  content: "\e5e0";
}
.icon-snowy-night:before {
  content: "\e5e1";
}
.icon-snowy-night-rainy:before {
  content: "\e5e2";
}
.icon-snowy-rainy:before {
  content: "\e5e3";
}
.icon-snowy-sunny:before {
  content: "\e5e4";
}
.icon-snowy-sunny-hail:before {
  content: "\e5e5";
}
.icon-snowy-sunny-rainy:before {
  content: "\e5e6";
}
.icon-snowy-thunder:before {
  content: "\e5e7";
}
.icon-snowy-thunder-night:before {
  content: "\e5e8";
}
.icon-snowy-thunder-sunny:before {
  content: "\e5e9";
}
.icon-snowy-windy:before {
  content: "\e5ea";
}
.icon-snowy-windy-night:before {
  content: "\e5eb";
}
.icon-snowy-windy-sunny:before {
  content: "\e5ec";
}
.icon-soccer:before {
  content: "\e5ed";
}
.icon-social-500px:before {
  content: "\e5ee";
}
.icon-social-aim:before {
  content: "\e5ef";
}
.icon-social-badoo:before {
  content: "\e5f0";
}
.icon-social-baidu-tieba:before {
  content: "\e5f1";
}
.icon-social-bbm-messenger:before {
  content: "\e5f2";
}
.icon-social-bebo:before {
  content: "\e5f3";
}
.icon-social-behance:before {
  content: "\e5f4";
}
.icon-social-blogger:before {
  content: "\e5f5";
}
.icon-social-bootstrap:before {
  content: "\e5f6";
}
.icon-social-brightkite:before {
  content: "\e5f7";
}
.icon-social-cloudapp:before {
  content: "\e5f8";
}
.icon-social-concrete5:before {
  content: "\e5f9";
}
.icon-social-delicious:before {
  content: "\e5fa";
}
.icon-social-designbump:before {
  content: "\e5fb";
}
.icon-social-designfloat:before {
  content: "\e5fc";
}
.icon-social-deviantart:before {
  content: "\e5fd";
}
.icon-social-digg:before {
  content: "\e5fe";
}
.icon-social-dotcms:before {
  content: "\e5ff";
}
.icon-social-dribbble:before {
  content: "\e600";
}
.icon-social-dribble:before {
  content: "\e601";
}
.icon-social-dropbox:before {
  content: "\e602";
}
.icon-social-ebuddy:before {
  content: "\e603";
}
.icon-social-ello:before {
  content: "\e604";
}
.icon-social-ember:before {
  content: "\e605";
}
.icon-social-envato:before {
  content: "\e606";
}
.icon-social-evernote:before {
  content: "\e607";
}
.icon-social-facebook:before {
  content: "\e608";
}
.icon-social-facebook-messenger:before {
  content: "\e609";
}
.icon-social-feedburner:before {
  content: "\e60a";
}
.icon-social-flikr:before {
  content: "\e60b";
}
.icon-social-folkd:before {
  content: "\e60c";
}
.icon-social-foursquare:before {
  content: "\e60d";
}
.icon-social-friendfeed:before {
  content: "\e60e";
}
.icon-social-ghost:before {
  content: "\e60f";
}
.icon-social-github:before {
  content: "\e610";
}
.icon-social-gnome:before {
  content: "\e611";
}
.icon-social-google-buzz:before {
  content: "\e612";
}
.icon-social-google-hangouts:before {
  content: "\e613";
}
.icon-social-google-map:before {
  content: "\e614";
}
.icon-social-google-plus:before {
  content: "\e615";
}
.icon-social-google-talk:before {
  content: "\e616";
}
.icon-social-hype-machine:before {
  content: "\e617";
}
.icon-social-instagram:before {
  content: "\e618";
}
.icon-social-kakaotalk:before {
  content: "\e619";
}
.icon-social-kickstarter:before {
  content: "\e61a";
}
.icon-social-kik:before {
  content: "\e61b";
}
.icon-social-kiwibox:before {
  content: "\e61c";
}
.icon-social-line:before {
  content: "\e61d";
}
.icon-social-linkedin:before {
  content: "\e61e";
}
.icon-social-linux-mint:before {
  content: "\e61f";
}
.icon-social-livejournal:before {
  content: "\e620";
}
.icon-social-magento:before {
  content: "\e621";
}
.icon-social-meetme:before {
  content: "\e622";
}
.icon-social-meetup:before {
  content: "\e623";
}
.icon-social-mixx:before {
  content: "\e624";
}
.icon-social-newsvine:before {
  content: "\e625";
}
.icon-social-nimbuss:before {
  content: "\e626";
}
.icon-social-odnoklassniki:before {
  content: "\e627";
}
.icon-social-opencart:before {
  content: "\e628";
}
.icon-social-oscommerce:before {
  content: "\e629";
}
.icon-social-pandora:before {
  content: "\e62a";
}
.icon-social-photobucket:before {
  content: "\e62b";
}
.icon-social-picasa:before {
  content: "\e62c";
}
.icon-social-pinterest:before {
  content: "\e62d";
}
.icon-social-prestashop:before {
  content: "\e62e";
}
.icon-social-qik:before {
  content: "\e62f";
}
.icon-social-qq:before {
  content: "\e630";
}
.icon-social-readernaut:before {
  content: "\e631";
}
.icon-social-reddit:before {
  content: "\e632";
}
.icon-social-renren:before {
  content: "\e633";
}
.icon-social-rss:before {
  content: "\e634";
}
.icon-social-shopify:before {
  content: "\e635";
}
.icon-social-silverstripe:before {
  content: "\e636";
}
.icon-social-skype:before {
  content: "\e637";
}
.icon-social-slack:before {
  content: "\e638";
}
.icon-social-slashdot:before {
  content: "\e639";
}
.icon-social-slidshare:before {
  content: "\e63a";
}
.icon-social-smugmug:before {
  content: "\e63b";
}
.icon-social-snapchat:before {
  content: "\e63c";
}
.icon-social-soundcloud:before {
  content: "\e63d";
}
.icon-social-spotify:before {
  content: "\e63e";
}
.icon-social-stack-exchange:before {
  content: "\e63f";
}
.icon-social-stack-overflow:before {
  content: "\e640";
}
.icon-social-steam:before {
  content: "\e641";
}
.icon-social-stumbleupon:before {
  content: "\e642";
}
.icon-social-tagged:before {
  content: "\e643";
}
.icon-social-technorati:before {
  content: "\e644";
}
.icon-social-telegram:before {
  content: "\e645";
}
.icon-social-tinder:before {
  content: "\e646";
}
.icon-social-trello:before {
  content: "\e647";
}
.icon-social-tumblr:before {
  content: "\e648";
}
.icon-social-twitch:before {
  content: "\e649";
}
.icon-social-twitter:before {
  content: "\e64a";
}
.icon-social-typo3:before {
  content: "\e64b";
}
.icon-social-ubercart:before {
  content: "\e64c";
}
.icon-social-viber:before {
  content: "\e64d";
}
.icon-social-viddler:before {
  content: "\e64e";
}
.icon-social-vimeo:before {
  content: "\e64f";
}
.icon-social-vine:before {
  content: "\e650";
}
.icon-social-virb:before {
  content: "\e651";
}
.icon-social-virtuemart:before {
  content: "\e652";
}
.icon-social-vk:before {
  content: "\e653";
}
.icon-social-wechat:before {
  content: "\e654";
}
.icon-social-weibo:before {
  content: "\e655";
}
.icon-social-whatsapp:before {
  content: "\e656";
}
.icon-social-xing:before {
  content: "\e657";
}
.icon-social-yahoo:before {
  content: "\e658";
}
.icon-social-yelp:before {
  content: "\e659";
}
.icon-social-youku:before {
  content: "\e65a";
}
.icon-social-youtube:before {
  content: "\e65b";
}
.icon-social-youtube-play:before {
  content: "\e65c";
}
.icon-social-zencart:before {
  content: "\e65d";
}
.icon-sof-drinks:before {
  content: "\e65e";
}
.icon-song-notes:before {
  content: "\e65f";
}
.icon-sort:before {
  content: "\e660";
}
.icon-sort-alt:before {
  content: "\e661";
}
.icon-sound-wave:before {
  content: "\e662";
}
.icon-sound-wave-alt:before {
  content: "\e663";
}
.icon-soup-bowl:before {
  content: "\e664";
}
.icon-space:before {
  content: "\e665";
}
.icon-space-shuttle:before {
  content: "\e666";
}
.icon-spanner:before {
  content: "\e667";
}
.icon-speech-comments:before {
  content: "\e668";
}
.icon-speed-boat:before {
  content: "\e669";
}
.icon-speed-meter:before {
  content: "\e66a";
}
.icon-spinner:before {
  content: "\e66b";
}
.icon-spinner-alt-1:before {
  content: "\e66c";
}
.icon-spinner-alt-2:before {
  content: "\e66d";
}
.icon-spinner-alt-3:before {
  content: "\e66e";
}
.icon-spinner-alt-4:before {
  content: "\e66f";
}
.icon-spinner-alt-5:before {
  content: "\e670";
}
.icon-spinner-alt-6:before {
  content: "\e671";
}
.icon-spoon-and-fork:before {
  content: "\e672";
}
.icon-spreadsheet:before {
  content: "\e673";
}
.icon-square:before {
  content: "\e674";
}
.icon-square-down:before {
  content: "\e675";
}
.icon-square-left:before {
  content: "\e676";
}
.icon-square-right:before {
  content: "\e677";
}
.icon-square-root:before {
  content: "\e678";
}
.icon-square-up:before {
  content: "\e679";
}
.icon-ssl-security:before {
  content: "\e67a";
}
.icon-stamp:before {
  content: "\e67b";
}
.icon-star:before {
  content: "\e67c";
}
.icon-star-alt-1:before {
  content: "\e67d";
}
.icon-star-alt-2:before {
  content: "\e67e";
}
.icon-star-hotel:before {
  content: "\e67f";
}
.icon-star-shape:before {
  content: "\e680";
}
.icon-steak:before {
  content: "\e681";
}
.icon-steering:before {
  content: "\e682";
}
.icon-stethoscope:before {
  content: "\e683";
}
.icon-stethoscope-alt:before {
  content: "\e684";
}
.icon-stock-mobile:before {
  content: "\e685";
}
.icon-stock-search:before {
  content: "\e686";
}
.icon-stop:before {
  content: "\e687";
}
.icon-strawberry:before {
  content: "\e688";
}
.icon-street-view:before {
  content: "\e689";
}
.icon-stretcher:before {
  content: "\e68a";
}
.icon-strike-through:before {
  content: "\e68b";
}
.icon-stripe:before {
  content: "\e68c";
}
.icon-stripe-alt:before {
  content: "\e68d";
}
.icon-student:before {
  content: "\e68e";
}
.icon-student-alt:before {
  content: "\e68f";
}
.icon-stylish-down:before {
  content: "\e690";
}
.icon-stylish-left:before {
  content: "\e691";
}
.icon-stylish-right:before {
  content: "\e692";
}
.icon-stylish-up:before {
  content: "\e693";
}
.icon-sub-listing:before {
  content: "\e694";
}
.icon-sub-sandwich:before {
  content: "\e695";
}
.icon-subscript:before {
  content: "\e696";
}
.icon-substitute:before {
  content: "\e697";
}
.icon-sun:before {
  content: "\e698";
}
.icon-sun-alt:before {
  content: "\e699";
}
.icon-sun-rise:before {
  content: "\e69a";
}
.icon-sun-set:before {
  content: "\e69b";
}
.icon-sunny:before {
  content: "\e69c";
}
.icon-sunny-day-temp:before {
  content: "\e69d";
}
.icon-superscript:before {
  content: "\e69e";
}
.icon-support:before {
  content: "\e69f";
}
.icon-support-faq:before {
  content: "\e6a0";
}
.icon-surface-tablet:before {
  content: "\e6a1";
}
.icon-surgeon:before {
  content: "\e6a2";
}
.icon-surgeon-alt:before {
  content: "\e6a3";
}
.icon-sushi:before {
  content: "\e6a4";
}
.icon-swimmer:before {
  content: "\e6a5";
}
.icon-swirl:before {
  content: "\e6a6";
}
.icon-swoosh-down:before {
  content: "\e6a7";
}
.icon-swoosh-left:before {
  content: "\e6a8";
}
.icon-swoosh-right:before {
  content: "\e6a9";
}
.icon-swoosh-up:before {
  content: "\e6aa";
}
.icon-table:before {
  content: "\e6ab";
}
.icon-table-tennis:before {
  content: "\e6ac";
}
.icon-tablets:before {
  content: "\e6ad";
}
.icon-tack-pin:before {
  content: "\e6ae";
}
.icon-taco:before {
  content: "\e6af";
}
.icon-tag:before {
  content: "\e6b0";
}
.icon-tags:before {
  content: "\e6b1";
}
.icon-tasks:before {
  content: "\e6b2";
}
.icon-tasks-alt:before {
  content: "\e6b3";
}
.icon-tattoo-wing:before {
  content: "\e6b4";
}
.icon-taxi:before {
  content: "\e6b5";
}
.icon-tea:before {
  content: "\e6b6";
}
.icon-tea-pot:before {
  content: "\e6b7";
}
.icon-teacher:before {
  content: "\e6b8";
}
.icon-team:before {
  content: "\e6b9";
}
.icon-team-alt:before {
  content: "\e6ba";
}
.icon-teddy-bear:before {
  content: "\e6bb";
}
.icon-telephone:before {
  content: "\e6bc";
}
.icon-telescope:before {
  content: "\e6bd";
}
.icon-tennis:before {
  content: "\e6be";
}
.icon-tennis-player:before {
  content: "\e6bf";
}
.icon-terminal:before {
  content: "\e6c0";
}
.icon-test-bottle:before {
  content: "\e6c1";
}
.icon-test-bulb:before {
  content: "\e6c2";
}
.icon-test-tube:before {
  content: "\e6c3";
}
.icon-test-tube-alt:before {
  content: "\e6c4";
}
.icon-text-height:before {
  content: "\e6c5";
}
.icon-text-width:before {
  content: "\e6c6";
}
.icon-thermometer:before {
  content: "\e6c7";
}
.icon-thermometer-alt:before {
  content: "\e6c8";
}
.icon-thief:before {
  content: "\e6c9";
}
.icon-thief-alt:before {
  content: "\e6ca";
}
.icon-thin-double-left:before {
  content: "\e6cb";
}
.icon-thin-double-right:before {
  content: "\e6cc";
}
.icon-thin-down:before {
  content: "\e6cd";
}
.icon-thin-left:before {
  content: "\e6ce";
}
.icon-thin-right:before {
  content: "\e6cf";
}
.icon-thin-up:before {
  content: "\e6d0";
}
.icon-thinder-light:before {
  content: "\e6d1";
}
.icon-throne:before {
  content: "\e6d2";
}
.icon-thumbs-down:before {
  content: "\e6d3";
}
.icon-thumbs-up:before {
  content: "\e6d4";
}
.icon-tick-boxed:before {
  content: "\e6d5";
}
.icon-tick-mark:before {
  content: "\e6d6";
}
.icon-ticket:before {
  content: "\e6d7";
}
.icon-tie:before {
  content: "\e6d8";
}
.icon-time:before {
  content: "\e6d9";
}
.icon-toggle-off:before {
  content: "\e6da";
}
.icon-toggle-on:before {
  content: "\e6db";
}
.icon-tomato:before {
  content: "\e6dc";
}
.icon-tools:before {
  content: "\e6dd";
}
.icon-tools-alt-1:before {
  content: "\e6de";
}
.icon-tools-alt-2:before {
  content: "\e6df";
}
.icon-tools-bag:before {
  content: "\e6e0";
}
.icon-tooth:before {
  content: "\e6e1";
}
.icon-tornado:before {
  content: "\e6e2";
}
.icon-touch:before {
  content: "\e6e3";
}
.icon-tow-truck:before {
  content: "\e6e4";
}
.icon-toy-ball:before {
  content: "\e6e5";
}
.icon-toy-cat:before {
  content: "\e6e6";
}
.icon-toy-duck:before {
  content: "\e6e7";
}
.icon-toy-elephant:before {
  content: "\e6e8";
}
.icon-toy-hand:before {
  content: "\e6e9";
}
.icon-toy-horse:before {
  content: "\e6ea";
}
.icon-toy-lattu:before {
  content: "\e6eb";
}
.icon-toy-train:before {
  content: "\e6ec";
}
.icon-track:before {
  content: "\e6ed";
}
.icon-tracking:before {
  content: "\e6ee";
}
.icon-tractor:before {
  content: "\e6ef";
}
.icon-traffic-light:before {
  content: "\e6f0";
}
.icon-train-line:before {
  content: "\e6f1";
}
.icon-train-steam:before {
  content: "\e6f2";
}
.icon-tram:before {
  content: "\e6f3";
}
.icon-transparent:before {
  content: "\e6f4";
}
.icon-trash:before {
  content: "\e6f5";
}
.icon-travelling:before {
  content: "\e6f6";
}
.icon-tree:before {
  content: "\e6f7";
}
.icon-tree-alt:before {
  content: "\e6f8";
}
.icon-triangle:before {
  content: "\e6f9";
}
.icon-trolley:before {
  content: "\e6fa";
}
.icon-trophy:before {
  content: "\e6fb";
}
.icon-trophy-alt:before {
  content: "\e6fc";
}
.icon-trowel:before {
  content: "\e6fd";
}
.icon-truck:before {
  content: "\e6fe";
}
.icon-truck-alt:before {
  content: "\e6ff";
}
.icon-truck-loaded:before {
  content: "\e700";
}
.icon-ui-add:before {
  content: "\e701";
}
.icon-ui-alarm:before {
  content: "\e702";
}
.icon-ui-battery:before {
  content: "\e703";
}
.icon-ui-block:before {
  content: "\e704";
}
.icon-ui-bluetooth:before {
  content: "\e705";
}
.icon-ui-brightness:before {
  content: "\e706";
}
.icon-ui-browser:before {
  content: "\e707";
}
.icon-ui-calculator:before {
  content: "\e708";
}
.icon-ui-calendar:before {
  content: "\e709";
}
.icon-ui-call:before {
  content: "\e70a";
}
.icon-ui-camera:before {
  content: "\e70b";
}
.icon-ui-cart:before {
  content: "\e70c";
}
.icon-ui-cell-phone:before {
  content: "\e70d";
}
.icon-ui-chat:before {
  content: "\e70e";
}
.icon-ui-check:before {
  content: "\e70f";
}
.icon-ui-clip:before {
  content: "\e710";
}
.icon-ui-clip-board:before {
  content: "\e711";
}
.icon-ui-clock:before {
  content: "\e712";
}
.icon-ui-close:before {
  content: "\e713";
}
.icon-ui-contact-list:before {
  content: "\e714";
}
.icon-ui-copy:before {
  content: "\e715";
}
.icon-ui-cut:before {
  content: "\e716";
}
.icon-ui-delete:before {
  content: "\e717";
}
.icon-ui-dial-phone:before {
  content: "\e718";
}
.icon-ui-edit:before {
  content: "\e719";
}
.icon-ui-email:before {
  content: "\e71a";
}
.icon-ui-file:before {
  content: "\e71b";
}
.icon-ui-fire-wall:before {
  content: "\e71c";
}
.icon-ui-flash-light:before {
  content: "\e71d";
}
.icon-ui-flight:before {
  content: "\e71e";
}
.icon-ui-folder:before {
  content: "\e71f";
}
.icon-ui-game:before {
  content: "\e720";
}
.icon-ui-handicapped:before {
  content: "\e721";
}
.icon-ui-head-phone:before {
  content: "\e722";
}
.icon-ui-home:before {
  content: "\e723";
}
.icon-ui-image:before {
  content: "\e724";
}
.icon-ui-keyboard:before {
  content: "\e725";
}
.icon-ui-laoding:before {
  content: "\e726";
}
.icon-ui-lock:before {
  content: "\e727";
}
.icon-ui-love:before {
  content: "\e728";
}
.icon-ui-love-add:before {
  content: "\e729";
}
.icon-ui-love-broken:before {
  content: "\e72a";
}
.icon-ui-love-remove:before {
  content: "\e72b";
}
.icon-ui-map:before {
  content: "\e72c";
}
.icon-ui-message:before {
  content: "\e72d";
}
.icon-ui-messaging:before {
  content: "\e72e";
}
.icon-ui-movie:before {
  content: "\e72f";
}
.icon-ui-music:before {
  content: "\e730";
}
.icon-ui-music-player:before {
  content: "\e731";
}
.icon-ui-mute:before {
  content: "\e732";
}
.icon-ui-network:before {
  content: "\e733";
}
.icon-ui-next:before {
  content: "\e734";
}
.icon-ui-note:before {
  content: "\e735";
}
.icon-ui-office:before {
  content: "\e736";
}
.icon-ui-password:before {
  content: "\e737";
}
.icon-ui-pause:before {
  content: "\e738";
}
.icon-ui-play:before {
  content: "\e739";
}
.icon-ui-play-stop:before {
  content: "\e73a";
}
.icon-ui-pointer:before {
  content: "\e73b";
}
.icon-ui-power:before {
  content: "\e73c";
}
.icon-ui-press:before {
  content: "\e73d";
}
.icon-ui-previous:before {
  content: "\e73e";
}
.icon-ui-rate-add:before {
  content: "\e73f";
}
.icon-ui-rate-blank:before {
  content: "\e740";
}
.icon-ui-rate-remove:before {
  content: "\e741";
}
.icon-ui-rating:before {
  content: "\e742";
}
.icon-ui-record:before {
  content: "\e743";
}
.icon-ui-remove:before {
  content: "\e744";
}
.icon-ui-reply:before {
  content: "\e745";
}
.icon-ui-rotation:before {
  content: "\e746";
}
.icon-ui-rss:before {
  content: "\e747";
}
.icon-ui-search:before {
  content: "\e748";
}
.icon-ui-settings:before {
  content: "\e749";
}
.icon-ui-social-link:before {
  content: "\e74a";
}
.icon-ui-tag:before {
  content: "\e74b";
}
.icon-ui-text-chat:before {
  content: "\e74c";
}
.icon-ui-text-loading:before {
  content: "\e74d";
}
.icon-ui-theme:before {
  content: "\e74e";
}
.icon-ui-timer:before {
  content: "\e74f";
}
.icon-ui-touch-phone:before {
  content: "\e750";
}
.icon-ui-travel:before {
  content: "\e751";
}
.icon-ui-unlock:before {
  content: "\e752";
}
.icon-ui-user:before {
  content: "\e753";
}
.icon-ui-user-group:before {
  content: "\e754";
}
.icon-ui-v-card:before {
  content: "\e755";
}
.icon-ui-video:before {
  content: "\e756";
}
.icon-ui-video-chat:before {
  content: "\e757";
}
.icon-ui-video-message:before {
  content: "\e758";
}
.icon-ui-video-play:before {
  content: "\e759";
}
.icon-ui-volume:before {
  content: "\e75a";
}
.icon-ui-weather:before {
  content: "\e75b";
}
.icon-ui-wifi:before {
  content: "\e75c";
}
.icon-ui-zoom-in:before {
  content: "\e75d";
}
.icon-ui-zoom-out:before {
  content: "\e75e";
}
.icon-umbrella:before {
  content: "\e75f";
}
.icon-umbrella-alt:before {
  content: "\e760";
}
.icon-under-construction:before {
  content: "\e761";
}
.icon-under-construction-alt:before {
  content: "\e762";
}
.icon-underline:before {
  content: "\e763";
}
.icon-undo:before {
  content: "\e764";
}
.icon-unique-idea:before {
  content: "\e765";
}
.icon-unity-hand:before {
  content: "\e766";
}
.icon-university:before {
  content: "\e767";
}
.icon-unlink:before {
  content: "\e768";
}
.icon-unlock:before {
  content: "\e769";
}
.icon-unlocked:before {
  content: "\e76a";
}
.icon-upload:before {
  content: "\e76b";
}
.icon-upload-alt:before {
  content: "\e76c";
}
.icon-usb:before {
  content: "\e76d";
}
.icon-usb-drive:before {
  content: "\e76e";
}
.icon-user:before {
  content: "\e76f";
}
.icon-user-alt-1:before {
  content: "\e770";
}
.icon-user-alt-2:before {
  content: "\e771";
}
.icon-user-alt-3:before {
  content: "\e772";
}
.icon-user-alt-4:before {
  content: "\e773";
}
.icon-user-alt-5:before {
  content: "\e774";
}
.icon-user-alt-6:before {
  content: "\e775";
}
.icon-user-alt-7:before {
  content: "\e776";
}
.icon-user-female:before {
  content: "\e777";
}
.icon-user-male:before {
  content: "\e778";
}
.icon-user-search:before {
  content: "\e779";
}
.icon-user-suited:before {
  content: "\e77a";
}
.icon-users:before {
  content: "\e77b";
}
.icon-users-alt-1:before {
  content: "\e77c";
}
.icon-users-alt-2:before {
  content: "\e77d";
}
.icon-users-alt-3:before {
  content: "\e77e";
}
.icon-users-alt-4:before {
  content: "\e77f";
}
.icon-users-alt-5:before {
  content: "\e780";
}
.icon-users-alt-6:before {
  content: "\e781";
}
.icon-users-social:before {
  content: "\e782";
}
.icon-van:before {
  content: "\e783";
}
.icon-van-alt:before {
  content: "\e784";
}
.icon-vector-path:before {
  content: "\e785";
}
.icon-vehicle-cement:before {
  content: "\e786";
}
.icon-vehicle-crane:before {
  content: "\e787";
}
.icon-vehicle-delivery-van:before {
  content: "\e788";
}
.icon-vehicle-dozer:before {
  content: "\e789";
}
.icon-vehicle-excavator:before {
  content: "\e78a";
}
.icon-vehicle-trucktor:before {
  content: "\e78b";
}
.icon-vehicle-wrecking:before {
  content: "\e78c";
}
.icon-verification-check:before {
  content: "\e78d";
}
.icon-video:before {
  content: "\e78e";
}
.icon-video-alt:before {
  content: "\e78f";
}
.icon-video-cam:before {
  content: "\e790";
}
.icon-video-clapper:before {
  content: "\e791";
}
.icon-visa:before {
  content: "\e792";
}
.icon-visa-alt:before {
  content: "\e793";
}
.icon-visa-electron:before {
  content: "\e794";
}
.icon-volcano:before {
  content: "\e795";
}
.icon-volleyball:before {
  content: "\e796";
}
.icon-volleyball-alt:before {
  content: "\e797";
}
.icon-volleyball-fire:before {
  content: "\e798";
}
.icon-volume-bar:before {
  content: "\e799";
}
.icon-volume-down:before {
  content: "\e79a";
}
.icon-volume-mute:before {
  content: "\e79b";
}
.icon-volume-off:before {
  content: "\e79c";
}
.icon-volume-up:before {
  content: "\e79d";
}
.icon-waiter:before {
  content: "\e79e";
}
.icon-waiter-alt:before {
  content: "\e79f";
}
.icon-wall:before {
  content: "\e7a0";
}
.icon-wall-clock:before {
  content: "\e7a1";
}
.icon-wallet:before {
  content: "\e7a2";
}
.icon-warning:before {
  content: "\e7a3";
}
.icon-warning-alt:before {
  content: "\e7a4";
}
.icon-washing-machine:before {
  content: "\e7a5";
}
.icon-water-bottle:before {
  content: "\e7a6";
}
.icon-water-drop:before {
  content: "\e7a7";
}
.icon-watermelon:before {
  content: "\e7a8";
}
.icon-wave:before {
  content: "\e7a9";
}
.icon-web:before {
  content: "\e7aa";
}
.icon-weed:before {
  content: "\e7ab";
}
.icon-western-union:before {
  content: "\e7ac";
}
.icon-western-union-alt:before {
  content: "\e7ad";
}
.icon-wheat:before {
  content: "\e7ae";
}
.icon-wheel:before {
  content: "\e7af";
}
.icon-wheelchair:before {
  content: "\e7b0";
}
.icon-whisle:before {
  content: "\e7b1";
}
.icon-wifi:before {
  content: "\e7b2";
}
.icon-wifi-alt:before {
  content: "\e7b3";
}
.icon-wifi-router:before {
  content: "\e7b4";
}
.icon-wii-u:before {
  content: "\e7b5";
}
.icon-win-trophy:before {
  content: "\e7b6";
}
.icon-wind:before {
  content: "\e7b7";
}
.icon-wind-scale-0:before {
  content: "\e7b8";
}
.icon-wind-scale-1:before {
  content: "\e7b9";
}
.icon-wind-scale-2:before {
  content: "\e7ba";
}
.icon-wind-scale-3:before {
  content: "\e7bb";
}
.icon-wind-scale-4:before {
  content: "\e7bc";
}
.icon-wind-scale-5:before {
  content: "\e7bd";
}
.icon-wind-scale-6:before {
  content: "\e7be";
}
.icon-wind-scale-7:before {
  content: "\e7bf";
}
.icon-wind-scale-8:before {
  content: "\e7c0";
}
.icon-wind-scale-9:before {
  content: "\e7c1";
}
.icon-wind-scale-10:before {
  content: "\e7c2";
}
.icon-wind-scale-11:before {
  content: "\e7c3";
}
.icon-wind-scale-12:before {
  content: "\e7c4";
}
.icon-wind-waves:before {
  content: "\e7c5";
}
.icon-windows-lumia:before {
  content: "\e7c6";
}
.icon-windy:before {
  content: "\e7c7";
}
.icon-windy-hail:before {
  content: "\e7c8";
}
.icon-windy-night:before {
  content: "\e7c9";
}
.icon-windy-raining:before {
  content: "\e7ca";
}
.icon-windy-sunny:before {
  content: "\e7cb";
}
.icon-windy-thunder:before {
  content: "\e7cc";
}
.icon-windy-thunder-raining:before {
  content: "\e7cd";
}
.icon-wireless-mouse:before {
  content: "\e7ce";
}
.icon-woman-bird:before {
  content: "\e7cf";
}
.icon-woman-in-glasses:before {
  content: "\e7d0";
}
.icon-worker:before {
  content: "\e7d1";
}
.icon-worker-group:before {
  content: "\e7d2";
}
.icon-world:before {
  content: "\e7d3";
}
.icon-wrench:before {
  content: "\e7d4";
}
.icon-xbox-360:before {
  content: "\e7d5";
}
.icon-xray:before {
  content: "\e7d6";
}
.icon-yacht:before {
  content: "\e7d7";
}
.icon-youtube-play:before {
  content: "\e7d8";
}
.icon-zigzag:before {
  content: "\e7d9";
}
.icon-zipped:before {
  content: "\e7da";
}
