@keyframes pulse {
    from {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }

    50% {
        opacity: 0.5;
        transform: scale3d(1.4, 1.4, 1.4);
    }

    to {
        opacity: 0;
        transform: scale3d(1, 1, 1);
    }
}
