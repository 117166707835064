// -----------------------------------------------------------------------------
// This file contains all styles related to the FLAGS components.
// -----------------------------------------------------------------------------

// ==== Flag ==== //

.flag {

  // ==== Type Notif
  &.flag-notif {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: rem-calc(0 4px);
    min-width: rem-calc(24px);
    height: rem-calc(24px);
    border-radius: rem-calc($border-radius-flag);
    color: white;
    text-align: center;
    font-size: rem-calc(12px);
    font-weight: bold;
    line-height: rem-calc(24px);

    i {
      position: absolute;
      @extend %posAbsoluteCenter;
      font-size: rem-calc(18px);

      &:before {
        position: absolute;
        @extend %posAbsoluteCenter;
      }
    }

    // ==== Base Color

    &.primary { background-color: $primary; &:after { background: $primary;}}
    &.green { background-color: $green; &:after { background: $green;}}
    &.yellow { background-color: $yellow;  &:after { background: $yellow;}}
    &.red { background-color: $red;  &:after { background: $red;}}

    // ==== Status Color

    &.MISSING { background-color: $status-MISSING; &:after { background: $status-MISSING;}}
    &.OUTDATED { background-color: $status-OUTDATED; &:after { background: $status-OUTDATED;}}
    &.UP_TO_DATE { background-color: $status-UPTODATE; &:after { background: $status-UPTODATE;}}
    &.TO_CHECK { background-color: $status-TOCHECK; &:after { background: $status-TOCHECK;}}
    &.PENDING { background-color: $status-PENDING; &:after { background: $status-PENDING;}}
    &.IN_PROGRESS { background-color: $status-IN_PROGRESS; &:after { background: $status-IN_PROGRESS;}}
    &.TERMINATED { background-color: $status-TERMINATED; &:after { background: $status-TERMINATED;}}
    &.ENDED { background-color: $status-TERMINATED; &:after { background: $status-TERMINATED;}}
    &.DRAFTED { background-color: $status-DRAFTED; &:after { background: $status-DRAFTED;}}
    &.PUBLISHED { background-color: $status-PUBLISHED; &:after { background: $status-PUBLISHED;}}
    &.ARCHIVED { background-color: $status-ARCHIVED; &:after { background: $status-ARCHIVED;}}

    // ==== Pulse Option

    &.pulse {
      position: relative;
      z-index: 2;

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: rem-calc(24px);
        animation: 2s infinite pulse;
        border-radius: rem-calc($border-radius-flag);
        content: '';
        z-index: -1;
      }
    }
  }

  // ==== Type Round
  &.flag-round {
    position: relative;
    text-align: center;
    border-radius: 100%;
    color: white;
    font-weight: 700;
    z-index: 1;

    &:before {
      position: absolute;
      @extend %posAbsoluteCenter;
      border-radius: 100%;
      content: '';
      z-index: -1;
    }

    // === Size
    &.small {
      min-height: rem-calc(16px); min-width: rem-calc(16px); line-height: rem-calc(16px);
      &:before {min-height: rem-calc(24px); min-width: rem-calc(24px);}
    }

    &.medium {
      min-height: rem-calc(28px); min-width: rem-calc(28px); line-height: rem-calc(28px);
      &:before {min-height: rem-calc(38px); min-width: rem-calc(39px);}
    }

    &.large {
      min-height: rem-calc(34px); min-width: rem-calc(34px); line-height: rem-calc(24px);
      &:before {min-height: rem-calc(44px); min-width: rem-calc(44px);}
    }

    &.xlarge {
      min-height: rem-calc(54px); min-width: rem-calc(54px); line-height: rem-calc(54px); font-size: rem-calc(28px);
      &:before {min-height: rem-calc(64px); min-width: rem-calc(64px);}
    }
  }

  // ==== Base Color
  &.primary {
    background-color: $primary;
    &:before { background: rgba($primary, 0.5);}

    &.hollow {
      background-color: $white;
      color: $primary;
      border: 2px solid $primary;
      line-height: rem-calc(24px);

      &:before {
        background: transparent;
        border: 1px solid rgba($primary, 0.5);
      }
    }
  }
  &.green { background-color: $green; &:after { background: $green;} &:before { background: rgba($green, 0.5);}}
  &.yellow { background-color: $yellow;  &:after { background: $yellow;} &:before { background: rgba($yellow, 0.5);}}
  &.red { background-color: $red;  &:after { background: $red;} &:before { background: rgba($red, 0.5);}}
  &.grey { background-color: $grey-medium;  &:after { background: $grey-medium;} &:before { background: rgba($grey-medium, 0.5);}}

  // ==== Status Color
  &.MISSING { background-color: $status-MISSING; &:after { background: $status-MISSING;} &:before { background: rgba($status-MISSING, 0.5);}}
  &.OUTDATED, &.NOT_CONFORMS { background-color: $status-OUTDATED; &:after { background: $status-OUTDATED;} &:before { background: rgba($status-OUTDATED, 0.5);}}
  &.UP_TO_DATE, &.CONFORMS { background-color: $status-UPTODATE; &:after { background: $status-UPTODATE;} &:before { background: rgba($status-UPTODATE, 0.5);}}
  &.TO_CHECK, &.PARTIAL_CONFORMS { background-color: $status-TOCHECK; &:after { background: $status-TOCHECK;} &:before { background: rgba($status-TOCHECK, 0.5);}}
  &.NOT_SUITABLE { background-color: $status-NOT_SUITABLE; &:after { background: $status-NOT_SUITABLE;} &:before { background: rgba($status-NOT_SUITABLE, 0.5);}}
  &.PENDING { background-color: $status-PENDING; &:after { background: $status-PENDING;} &:before { background: rgba($status-PENDING, 0.5);}}
  &.IN_PROGRESS { background-color: $status-IN_PROGRESS; &:after { background: $status-IN_PROGRESS;} &:before { background: rgba($status-IN_PROGRESS, 0.5);}}
  &.RUNNING { background-color: $status-IN_PROGRESS; &:after { background: $status-IN_PROGRESS;} &:before { background: rgba($status-IN_PROGRESS, 0.5);}}
  &.IN_ERROR { background-color: $app-error; &:after { background: $app-error;} &:before { background: rgba($app-error, 0.5);}}
  &.TERMINATED { background-color: $status-TERMINATED; &:after { background: $status-TERMINATED;} &:before { background: rgba($status-TERMINATED, 0.5);}}
  &.ENDED { background-color: $status-TERMINATED; &:after { background: $status-TERMINATED;} &:before { background: rgba($status-TERMINATED, 0.5);}}
  &.DRAFTED { background-color: $status-DRAFTED; &:after { background: $status-DRAFTED;} &:before { background: rgba($status-DRAFTED, 0.5);}}
  &.PUBLISHED { background-color: $status-PUBLISHED; &:after { background: $status-PUBLISHED;} &:before { background: rgba($status-PUBLISHED, 0.5);}}
  &.ARCHIVED { background-color: $status-ARCHIVED; &:after { background: $status-ARCHIVED;} &:before { background: rgba($status-ARCHIVED, 0.5);}}
}

// ==== Status ==== //

.status {
  font-weight: 500;

  .flag {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(8px);
  }

  .name-status {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(8px);
  }
}

