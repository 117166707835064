// For icon table
.dragIndicator {
  color: $app-grey_medium;
  @extend %transition;
}

._dragColumns {
  tr {
    cursor: grab;
    @extend %transition;

    &:hover {
      @extend %box-shadow;
      @extend %transition;

      .dragIndicator {
        color: $app-primary;
        @extend %transition;
      }
    }
  }
}

// DRAGULA SCSS FROM OFFICIAL GITHUB - https://github.com/valor-software/ng2-dragula

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
