// -----------------------------------------------------------------------------
// This file contains all styles related to the TOOLTIPS components.
// -----------------------------------------------------------------------------

// ==== Custom Tooltips ==== //
.tooltip {
    max-width: rem-calc(654px) !important;
    white-space: normal;
    line-height: 1.2;
    z-index: 999999 !important;

  .scoreTooltipContent {
    color: $app-white;
    border: none;

    thead {
      border: 1px solid rgba($app-white, 0.3);
      background: transparent;

      th {
        color: $app-white;
      }
    }
    tbody {
      border: 1px solid rgba($app-white, 0.3);
      background: transparent;

      tr {
        border: 1px solid rgba($app-white, 0.3);
      }
    }
  }
}



// ==== Adjust specific content in Tooltips ==== //
.scoreTooltipContent {
  color: $app-black;
  text-align: left;

  .additionalScoreTags {
    gap: rem-calc(8px);
  }

  .label {
    text-align: center;

    &.JARO_WINKLER {
      color: mix(#e0c1c1, black, 20%);
      background-color: #e0c1c1;
    }

    &.LEVENSHTEIN {
      color: mix(#b0d0ed, black, 20%);
      background-color: #b0d0ed;
    }

    &.DOUBLE_METAPHONE {
      color: mix(#cdadd9, black, 20%);
      background-color: #cdadd9;
    }
  }
}
