// -----------------------------------------------------------------------------
// This file contains all styles related to the CAROUSEL components.
// -----------------------------------------------------------------------------

// ==== Carousel Control ==== //

.carousel-control {
  margin: rem-calc(8px 0);
  text-align: center;

  .carousel-control-bullet {
    display: inline-block;
    margin: rem-calc(8px);
    height: rem-calc(18px);
    width: rem-calc(18px);
    background: $primary-dark;
    border-radius: 100%;
    @extend %globalTransition;
    cursor: pointer;

    &:hover, &:active {
      @extend %globalTransition;
      transform: scale(1.15);
    }

    &.active {
      transform: scale(1.5);
      cursor: default;

      &:after {
        position: absolute;
        @extend %posAbsoluteCenter;
        height: rem-calc(12px);
        width: rem-calc(12px);
        border-radius: 100%;
        background-color: white;
        content: '';
      }
    }
  }
}
