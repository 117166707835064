// -----------------------------------------------------------------------------
// This file contains all styles related to the TIMELINE components.
// -----------------------------------------------------------------------------

// ==== History Timeline ==== //

.astree-status {
  margin-bottom: rem-calc(12px);
  border: 1px solid $primary;
  border-radius: rem-calc(5px);
  padding: rem-calc(10px);

  .logo-astree {
    width: rem-calc(29px);
    margin-right: rem-calc(10px);
  }

  &-header {
    color: $primary;
    padding: rem-calc(10px) 0;
  }

  &-contact {
    text-align: center;
  }

  &-list {
    list-style: none;
    margin: 0;

    &-item {
      margin-bottom: rem-calc(12px);

      &-name {
        color: $grey-dark;
      }

      .status-label-select {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 94px);
        font-size: rem-calc(14px);
        text-align: left;
        font-weight: 500;
        color: $primary;

        &.state-neutral  { color: $primary;}
        &.state-MISSING  { color: $status-MISSING;}
        &.state-OUTDATED, &.state-NOT_CONFORMS  { color: $status-OUTDATED;}
        &.state-UP_TO_DATE, &.state-CONFORMS  { color: $status-UPTODATE;}
        &.state-TO_CHECK, &.state-PARTIAL_CONFORMS  { color: $status-TOCHECK;}
        &.state-NOT_SUITABLE  { color: $status-NOT_SUITABLE;}
      }
    }
  }
}

.history-timeline {
  height: 100%;

  .history-timeline-header {
    height: auto;
    padding-left: rem-calc(24px);
  }

  .history-timeline-list {
    position: relative;
    padding-top: rem-calc(16px);

    &:before {
      position: absolute;
      top: 0;
      left: rem-calc(9.6px);
      height: 100%;
      width: rem-calc(2px);
      background: $primary;
      content: '';
    }

    .history-timeline-item {
      position: relative;
      display: block;
      padding-top: rem-calc(4px);
      padding-left: rem-calc(34px);
      margin-bottom: rem-calc(16px);

      svg {
        position: absolute;
        left: 0;
        top: 0;
        height: rem-calc(28px);
      }

      .history-timeline-item-title {
        margin-bottom: 0;
        font-weight: normal;
      }

      // === Status

      &.state-neutral { .history-timeline-item-title { color: $primary;} .cls-1 { stroke: $primary;} .cls-2 {  fill:  $primary;}}
      &.state-MISSING { .history-timeline-item-title { color: $status-MISSING;} .cls-1 { stroke: $status-MISSING;} .cls-2 {  fill: $status-MISSING;}}
      &.state-OUTDATED, &.state-NOT_CONFORMS { .history-timeline-item-title { color: $status-OUTDATED;} .cls-1 { stroke: $status-OUTDATED;} .cls-2 {  fill: $status-OUTDATED;}}
      &.state-UP_TO_DATE, &.state-CONFORMS { .history-timeline-item-title { color: $status-UPTODATE;} .cls-1 { stroke: $status-UPTODATE;} .cls-2 {  fill: $status-UPTODATE;}}
      &.state-TO_CHECK, &.state-PARTIAL_CONFORMS { .history-timeline-item-title { color: $status-TOCHECK;} .cls-1 { stroke: $status-TOCHECK;} .cls-2 {  fill: $status-TOCHECK}}
      &.state-NOT_SUITABLE { .history-timeline-item-title { color: $status-NOT_SUITABLE;} .cls-1 { stroke: $status-NOT_SUITABLE;} .cls-2 {  fill: $status-NOT_SUITABLE}}
    }
  }

  // ==== Bullet Styles

  .cls-1 {fill: #fff; stroke-width: 3px;}
  .cls-3 { stroke: none;}
  .cls-4 { fill: none;}
}
