@font-face {
  font-family: "beclm-cu";
  src:url($common-font-main-path + "icons/beclm-cu-modules.eot");
  src:url($common-font-main-path + "icons/beclm-cu-modules.eot?#iefix") format("embedded-opentype"),
  url($common-font-main-path + "icons/beclm-cu-modules.woff") format("woff"),
  url($common-font-main-path + "icons/beclm-cu-modules.ttf") format("truetype"),
  url($common-font-main-path + "icons/beclm-cu-modules.svg#beclm-cu") format("svg");
  font-weight: normal;
  font-style: normal;
}


[class^="icon-module-"], [class*=" icon-module-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'beclm-cu-modules' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-module-adverse-media:before {
  content: "\e90c";
}
.icon-module-person:before {
  content: "\e911";
}
.icon-module-adequation:before {
  content: "\e900";
}
.icon-module-admin:before {
  content: "\e901";
}
.icon-module-astree:before {
  content: "\e902";
}
.icon-module-blacklist:before {
  content: "\e903";
}
.icon-module-company:before {
  content: "\e904";
}
.icon-module-compliance-binder:before {
  content: "\e905";
}
.icon-module-dashboard:before {
  content: "\e906";
}
.icon-module-e-learning:before {
  content: "\e907";
}
.icon-module-lab:before {
  content: "\e908";
}
.icon-module-pep:before {
  content: "\e909";
}
.icon-module-product:before {
  content: "\e90a";
}
.icon-module-service-desk:before {
  content: "\e90b";
}

