.label {
  font-weight: bold;

  &.primary {
    color: $white;
  }

  &.success {
    color: $white;
  }

  &.dataSourceTag-item {
    background: rgba($dashboardPrimary, 0.1);
    color: $dashboardPrimary;

    i {
      font-size: rem-calc(12px);
      margin-right: rem-calc(4px);
    }
  }
}
