// -----------------------------------------------------------------------------
// This file contains all styles related to the CALLOUT component.
// -----------------------------------------------------------------------------

// ==== Custom Style Callout (base in vendors/foundation-variables.scss) ==== //

.callout {
  position: relative;
  line-height: rem-calc(16px);
  font-weight: 500;
  width: 100%;
  text-align: center;
  overflow: hidden;
  animation: showTop 0.2s ease-in-out;

  ul { li { text-align: left;}}

  &.no-animation { animation: none;}

  // ==== Size

  &.small { font-size: rem-calc(13px); line-height: rem-calc(16px);}
  &.medium { font-size: rem-calc(16px); line-height: rem-calc(18px);}
  &.large { padding: 12px 16px !important; font-size: rem-calc(24px); line-height: rem-calc(28px);}


  // ==== Colors

  &.primary { color: $app-primary;}
  &.success { color: $green;}
  &.alert { color: $red;}

  // ==== Option icons

  &.has-icon-alert {
    &:after {
      @extend %calloutIcon;
      content: '\e7a3';
    }
  }

  &.has-icon-info {
    &:after {
      @extend %calloutIcon;
      content: '\e3f0';
    }
  }

  &.has-icon-check {
    &:after {
      @extend %calloutIcon;
      content: '\e70f';
    }
  }
}
