// -----------------------------------------------------------------------------
// This file contains all styles related to the MESSAGE components.
// -----------------------------------------------------------------------------


// ==== Infos Messages ==== //

.info-message {
  text-align: center;

  .info-message-illu {
    display: block;
    margin: 0 auto;
    margin-bottom: rem-calc(16px);
    height: rem-calc(140px);
    width: rem-calc(140px);
    text-align: center;

    img {
      width: 100%;

    }
  }

  .info-message-title {
    margin-bottom: 0;
  }

  .info-message-text {
    color: $grey-dark;
  }
}
