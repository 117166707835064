// -----------------------------------------------------------------------------
// /!\ File to delete /!\
// -----------------------------------------------------------------------------


// -----------------------------------------------------------------------------
// /!\ Duplicate style for component operation-control-step-status /!\
// -----------------------------------------------------------------------------

$operationStepStatusColor: (
  'UNPROCESSED' : $grey-medium,
  'IN_PROGRESS' : $app-in-progress-color,
  'REINFORCED_CONTROL' : $app-reinforced-color,
  'VALIDATED' : $app-success-color,
  'REJECTED' : $app-error-color
);

.operationStepStatus {
  display: inline-block;
  font-size: rem-calc(12px);
  padding: {
    top: rem-calc(4px);
    right: rem-calc(24px);
    bottom: rem-calc(4px);
    left: rem-calc(24px);
  };
  background-color: $grey-medium;
  border-radius: rem-calc(100px);
  font-weight: normal;

  @each $cssClass, $color in $operationStepStatusColor {
    &.is_#{$cssClass} {
      color: if(lightness($color) > 70, $black, white);
      background-color: $color;
    }
  }
}
