// ==== Colors ==== //

.white { color: white;}
.primary { color: $app-primary;}
.black { color: $black;}
.red { color: $red;}
.grey-light { color: $grey-light;}
.grey-medium { color: $grey-medium;}
.grey-dark { color: $grey-dark;}

._white { color: white;}
._primary { color: $app-primary;}
._secondary { color: $app-primary-sub;}
._black { color: $black;}
._red { color: $red;}
._yellow { color: $yellow;}
._grey-light { color: $grey-light;}
._grey-medium { color: $grey-medium;}
._grey-dark { color: $grey-dark;}

// ==== Colors Text Status  ==== //

.MISSING { color: $status-MISSING;}
.OUTDATED, .NOT_CONFORMS { color: $status-OUTDATED;}
.TO_CHECK, .PARTIAL_CONFORMS { color: $status-TOCHECK;}
.UP_TO_DATE, .CONFORMS { color: $status-UPTODATE;}
.NOT_SUITABLE { color: $status-NOT_SUITABLE;}

// ==== Read Only ==== //

.read-value {
  display: block;
  margin-bottom: rem-calc(8px);
  font-weight: bold;
}

// ==== Mention ====//

.mention {
  color: $grey-medium;

  i {
    position: relative;
    top: rem-calc(2px);
    padding-right: rem-calc(8px);
  }
}

// ==== List ====//

.basic-list {
  li {
    line-height: rem-calc(16px);
    list-style: none;
    margin-bottom: rem-calc(8px);
  }

  li:before {
    display: inline-block;
    position: relative;
    top: rem-calc(-4px);
    vertical-align: top;
    font-family: 'beclm-cu';
    content: '\e2b5';
    margin: rem-calc(0 4px 0 -24px);
    color: $primary;
    font-size: rem-calc(24px);
  }
}

// ================ //
// Text
// ================ //

.mentionForm {
  display: block;
  line-height: 1.4;
  margin-top: rem-calc(8px);
}

// ==== Links ==== //

.link {
  cursor: pointer;
  text-decoration: underline;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }
}

// ==== Title ==== //

.title { font-weight: bold;}

.big-title {
  font-size: rem-calc(38px);
  font-weight: 600;

  @include breakpoint(small only) {
    font-size: rem-calc(28px);
  }
}

.section-title {
  margin-bottom: 0;
  color: $section-title;
  text-transform: uppercase;

  &.text-normal { text-transform: none;}

  // === Size

  &.large { font-size: rem-calc(28px);}

  i {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    top: rem-calc(1px);
    margin-right: rem-calc(8px);
  }
}

// ==== Label Value ==== //

.labelValue {
  line-height: 1.2;
  font-size: rem-calc(12px);
}

// ==== Message Box ====//

.messageBox {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: rem-calc(4px);
  padding: rem-calc(4px 8px);
  white-space: initial;
  line-height: 1.2;
  font-size: rem-calc(12px);
  border-radius: rem-calc(8px);
  background: rgba($app-grey_light, 0.15);

  i {
    font-size: rem-calc(14px);
  }
}
