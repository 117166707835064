body, html {
  height: 100%;

  * { outline:none;}

  ::selection {
    background: $primary; /* WebKit/Blink Browsers */
    color: white;
  }
  ::-moz-selection {
    background: $primary; /* Gecko Browsers */
    color: white;
  }
}

.block-advice a {

  &:visited {
    color: $grey-light;
  }
  &:hover {
    color: $grey-dark;
  }
  &:active {
    color: $grey-light;
  }
  color: $white;
  text-decoration: underline;
}

.dragIcon {
  color: $app-grey_dark;
  cursor: grab;
  opacity: 0.5;

  &:hover, &:focus {
    @extend %globalTransition;
    opacity: 1;
  }

  &:active {
    cursor: grabbing;
  }
}

