// -----------------------------------------------------------------------------
// This file contains all styles related to the TABS components.
// -----------------------------------------------------------------------------

.tabs {
  border: none;
  background: transparent;

  .tab-item {
    font-weight: 500;
    font-size: rem-calc(15px);
    text-align: center;
    border-bottom: rem-calc(2px) solid $app-primary;
    @extend %globalTransition;

    &.active {
      .tab-item-link {
        @extend %globalTransition;
        background-color: $app-primary;
        color: white;

        &:hover, &:focus, &:active {
          background-color: $app-primary;
          color: white;
          cursor: default;
        }
      }
    }

    &:hover, &:focus, &:active {
      .tab-item-link {
        @extend %globalTransition;
        background-color: $grey-light;
      }
    }

    .tab-item-link {
      display: block;
      height: 100%;
      width: 100%;
      padding: rem-calc(8px);
      color: $grey-dark;
      border-radius: 0 rem-calc(20px) 0 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &.horizontal {
    .tab-item { display: inline-block;}
  }

  &.vertical {
    .tab-item { display: block; border-radius: 0;}
    .tab-item-link { border-radius: 0;}
  }
}
