// -----------------------------------------------------------------------------
// This file contains all styles related to the BUTTONS components.
// -----------------------------------------------------------------------------

// ==== Custom Button (principal settings in file foundation-variables.scss)  ==== //

.button {
  position: relative;
  font-weight: 600;
  white-space: nowrap;

  // ==== SIZE  ==== //
  &.small {
    font-size: rem-calc(13px);

    i {
      font-size: rem-calc(16px);
    }
  }

  // ==== TYPE ROUND ==== //
  &.round {
    border-radius: 100%;
    min-width: inherit;

    i:before {
      position: absolute;
      @extend %posAbsoluteCenter;
      margin: 0;
    }

    // ==== Size

    &.small {
      font-size: rem-calc(16px);
      height: rem-calc(28px);
      width: rem-calc(28px);
      padding: rem-calc(4px);
    }

    &.medium {
      font-size: rem-calc(18px);
      height: rem-calc(34px);
      width: rem-calc(34px);
      padding: rem-calc(4px);
    }

    &.large {
      font-size: rem-calc(34px);
      height: rem-calc(64px);
      width: rem-calc(64px);
      padding: rem-calc(4px);

      .spinner-btn { transform: translate(-50%, -50%) scale(4);}
    }

    &.xlarge {
      font-size: rem-calc(64px);
      height: rem-calc(100px);
      width: rem-calc(100px);
      padding: rem-calc(18px);
      .spinner-btn { transform: translate(-50%, -50%) scale(6);}
    }

    .spinner-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(2);
      margin: 0;
    }
  }

  // ==== TYPE HOLLOW === //
  &.hollow {
    padding: rem-calc(10px 14px);
  }

  // ==== LOADER BTN ==== //
  .spinner-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: rem-calc(10px);
    height: rem-calc(10px);
    background: url($common-svg-path + "spinner-white.svg");
    background-repeat: no-repeat;
    transform: scale(2);
    background-size: 100%;

    &.left { margin-right: rem-calc(8px);}
    &.right { margin-left: rem-calc(8px);}
  }

  // ==== ADJUST COLORS ==== //
  &.success {
    color: $white;
    border: rem-calc(1px) solid $app-success;

    &:hover, &:active, &:focus { color: white;}
    &:disabled { color: $white;  &:hover, &:active, &:focus {color: $white;}}
  }

  &.grey {
    color: $grey-dark;
    border-color: $grey-medium;
    background: $grey-medium;

    &.hollow {
      background: transparent;
      color: $grey-medium;

      &:hover, &:active, &:focus { color: $app-primary;}
    }
  }
}

// ==== OPTIONS ICONS ==== //

.has-icon-left {
  padding-top: rem-calc(8px);
  padding-bottom:  rem-calc(7px);

  i {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(8px) !important;
  }
}

.has-icon-right {
  padding-top: rem-calc(10px) !important;

  i {
    position: relative;
    top: rem-calc(1px);
    margin-left: rem-calc(8px) !important;
  }
}
