@import "../utils/variables";

/* work-sans-100 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Thin'), local('WorkSans-Thin'),
  url($common-font-main-path + 'police/work-sans-v3-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-100.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-100.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-200 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Work Sans ExtraLight'), local('WorkSans-ExtraLight'),
  url($common-font-main-path + 'police/work-sans-v3-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-200.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-200.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-300 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Light'), local('WorkSans-Light'),
  url($common-font-main-path + 'police/work-sans-v3-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-300.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-300.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-regular - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Work Sans'), local('WorkSans-Regular'),
  url($common-font-main-path + 'police/work-sans-v3-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-regular.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-500 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Medium'), local('WorkSans-Medium'),
  url($common-font-main-path + 'police/work-sans-v3-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-500.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-500.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
  url($common-font-main-path + 'police/work-sans-v3-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-600.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
  url($common-font-main-path + 'police/work-sans-v3-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-700.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-800 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Work Sans ExtraBold'), local('WorkSans-ExtraBold'),
  url($common-font-main-path + 'police/work-sans-v3-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-800.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-800.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-900 - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url($common-font-main-path + 'police/work-sans-v3-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Black'), local('WorkSans-Black'),
  url($common-font-main-path + 'police/work-sans-v3-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($common-font-main-path + 'police/work-sans-v3-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-900.woff') format('woff'), /* Modern Browsers */
  url($common-font-main-path + 'police/work-sans-v3-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url($common-font-main-path + 'police/work-sans-v3-latin-900.svg#WorkSans') format('svg'); /* Legacy iOS */
}
