// -----------------------------------------------------------------------------
// This file contains all styles related to the ANIMATIONS components.
// -----------------------------------------------------------------------------

// ====  Effect Pulse  ==== //

@keyframes pulse {
  from {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }

  50% {
    opacity: 0.5;
    transform: scale3d(1.4, 1.4, 1.4);
  }

  to {
    opacity: 0;
    transform: scale3d(1, 1, 1);
  }
}


// ====  Effects loading  ==== //

@keyframes effectLoading {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

// ====  Effect Bounce  ==== //

@keyframes bounce {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-15px);
  }
}
