.statIndicator {
  display: block;
  margin-top: rem-calc(14px);
  padding: rem-calc(4px 8px);
  border: rem-calc(2px) solid darken($app-warning-color, 5%);
  font-size: rem-calc(12px);
  font-weight: bold;
  background-color: $app-warning-color;
  color: $white;

  i {
    display: inline-block;
    vertical-align: middle;
    margin-right: rem-calc(8px);
    font-size: rem-calc(18px);
  }
}
