/**
 *  Custom foundation cards
 */

.card {
  border-color: $app-grey_light;

  .card-footer {
    background-color: $special-grey;
    border-top: $grey-light 1px solid ;
    padding: rem-calc(12px);
  }

  .card-divider {

    &._primary {
      background: $app-primary;
    }

    &._special {
      background: $app-special;
    }

    &._hasGrid {
      display: block;
    }
  }
}
