.button {
  position: relative;
  margin-bottom: 0;
  font: {
    weight: bold;
  };
  border: none;
  box-shadow: 0 1px 3px rgba($app-primary, 0.50);
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;
    border-radius: $button-radius;
  }

  @extend %rippleEffect;
}
