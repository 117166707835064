// -----------------------------------------------------------------------------
// This file contains all styles related to the UPLOAD components.
// -----------------------------------------------------------------------------

// ==== Drop Zone ==== //

.my-drop-zone {
  border-radius: rem-calc(8px);
  margin: 0;
  padding: rem-calc(15px 0 15px 0);
  text-align: center;
  border: dotted 3px lightgray;
  @extend %globalTransition;

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    filter: grayscale(100%);
  }

  &.nv-file-over {
    background-color: $special-grey;
    @extend %globalTransition;
    border-color: $primary;

    .form-label { color: $primary;}

    svg {
      .arrow {
        animation: 2s infinite bounce;
      }
    }
  }

  label.button {
    margin-bottom: 0!important;
  }

  .illu-drop {
    svg {
      width: rem-calc(80px);
      .st0{fill:$app-primary !important;}
      .arrow{fill:$app-primary !important;}
    }
  }
}

.input-file {
  display: none;
}
